import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const TDataDashboard = () => {
  // Common chart options for line charts
  const chartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true, // Enable tooltips
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.labelr; // Show only the dataset label
          },
          title: function () {
            return ''; // Hide the tooltip title
          },
        },
      },
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Training Compute (long scale)', // X-axis label
          color: 'white',
          font: {
            weight: '600', // Semi-bold font
            size: 14,
          },
        },
        ticks: { color: 'white' },
      },
      y: {
        title: {
          display: true,
          text: 'Accuracy (%)', // Y-axis label
          color: 'white',
          font: {
            weight: '600', // Semi-bold font
            size: 14,
          },
        },
        ticks: { color: 'white' },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: true, // Hovering will only affect the line directly under the cursor
    },
    elements: {
      line: {
        borderWidth: 3, // Thicker lines for better visibility
      },
      // point: {
      //   radius: 0, // Hide points to emphasize lines
      // },
    },
  };

  // Data for Model Improvement over Time (Line Chart)
  const modelImprovementData = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'], // Adjusted labels for clarity
    datasets: [
      {
        labelr: "diAI's Accuracy during Training",
        data: [40.0, 42.0, 52.0, 56.0, 60.0, 65.0, 72.0, 80.0, 83.0, 84.0, 90.0],
        borderColor: 'grey',
        backgroundColor: 'grey',
        fill: false,
      },
      {
        labelr: "diAI's Accuracy during Test Time",
        data: [40.0, 43.0, 46.0, 50.0, 56.0, 65.0, 70.0, 78.0, 86.0, 92.0, 96.0],
        borderColor: 'white',
        backgroundColor: 'white',
        fill: false,
      },
    ],
  };

  return (
    <div className="bg-black text-white p-8">
      <div className="flex space-x-8 mb-8 text-gray-500 text-sm">
        {/* Model Improvement over Time (Line Chart) */}
        <div style={{ width: '800px' }}>
          <h2 className="text-center text-lg font-bold mb-4">Model Architecture and Performance</h2>
          <Line data={modelImprovementData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default TDataDashboard;

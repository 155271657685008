import React, { useState, useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import Graphs from './graphsdata'

export default function NewResearch() {
  const [activeSection, setActiveSection] = useState('');
  const [loading, setLoading] = useState(true);
  const [sidebarVisible, setSidebarVisible] = useState(false); // New state for sidebar visibility

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll('section');
      
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollPosition >= sectionTop - 50 && scrollPosition < sectionTop + sectionHeight - 50) {
          setActiveSection(section.id);
        }
      });

      // Check if user has scrolled past the first section (adjust 500px as needed)
      if (scrollPosition >= 530) {
        setSidebarVisible(true); // Make sidebar visible after scrolling 500px
      } else {
        setSidebarVisible(false); // Hide sidebar if scroll position is less than 500px
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 200,
      easing: "ease-out-cubic",
    });
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
      <div className="min-h-screen bg-black text-[#A3A3A3] font-[system-ui]">
        <a className="p-2 text-[10px]" href="/research"><span aria-hidden="true">&larr;</span> Research </a>

        {/* Navigation */}
        <nav
          className={`fixed left-8 top-12 space-y-4 transition-opacity duration-700 ${sidebarVisible ? 'opacity-100' : 'opacity-0'}`}
          style={{ transition: 'opacity 1s ease' }}
        >
          <button onClick={scrollToTop} className="block text-[15px] hover:text-white transition-colors"></button>
          <Link to="methods" spy={true} smooth={true} duration={500} className={`block text-[15px] hover:text-white transition-colors ${activeSection === 'methods' ? 'text-white' : ''}`}>
            Superior nutritional<br /> intelligence through<br /> few-shot learning
          </Link>
          <Link to="preferences" spy={true} smooth={true} duration={500} className={`block text-[15px] hover:text-white transition-colors ${activeSection === 'preferences' ? 'text-white' : ''}`}>
            Built-in safety and<br /> accuracy
          </Link>
          <Link to="limitations" spy={true} smooth={true} duration={500} className={`block text-[15px] hover:text-white transition-colors ${activeSection === 'limitations' ? 'text-white' : ''}`}>
            Availability
          </Link>
          <Link to="next-steps" spy={true} smooth={true} duration={500} className={`block text-[15px] hover:text-white transition-colors ${activeSection === 'next-steps' ? 'text-white' : ''}`}>
            Next steps
          </Link>
        </nav>

        {/* Main Content */}
        <main className="max-w-[900px] mx-auto px-8 py-12">
          <div className="space-y-12">
            <div>
              <h2 className="text-[47px] font-semibold text-white mb- text-center">Advancing AI-Driven Food Detection</h2>
              <p className="text-[18px] text-white mt-3 text-center">
                Introducing our breakthrough few-shot prompted model for <br /> nutritional intelligence
              </p>
            </div>
            <div className="max-w-[670px] mx-auto">

              <Element name="results">
                <p className="text-[18px] mt-10 text-white leading-relaxed mb-8">
                  diAI represents a significant advancement in making nutritional tracking seamlessly integrated into everyday conversations. Our few-shot prompted model, built on Ollama, achieves unprecedented accuracy in food detection and nutritional analysis through natural language understanding. diAI scores 92% on Food Recognition Accuracy (FRA) and currently outperforms existing solutions in real-time carbohydrate estimation.
                  <br /><br />
                  Today, diAI supports text-based conversational inputs, enabling accurate nutritional tracking through natural language interactions. The model leverages few-shot learning to understand and analyze food descriptions with minimal contextual examples.
                </p>
              </Element>

              <Element name="methods">
                <section id="methods" className="pt-12">
                  <h2 className="text-[28px] text-white mb-6">Superior nutritional intelligence through few-shot learning</h2>
                  <p className="text-[18px] text-white leading-relaxed mb-6">
                    diAI demonstrates breakthrough performance in conversational food detection:
                  </p>
                  <ul className="list-disc pl-6 text-white space-y-4 text-[18px]">
                    <li>High Food Recognition Accuracy: Achieving 92% accuracy in identifying foods across diverse conversational contexts, diAI understands food types and nutritional properties through natural language input.</li>
                    <li>Robust Carbohydrate Estimation: With 90% accuracy in estimating carbohydrate content, diAI accurately determines nutritional values based on both descriptive text and real-world data.</li>
                    <li>Context-Aware Learning: The ability to infer and adapt to new food items with minimal contextual examples, ensuring real-time adaptability.</li>
                    <li>Real-Time Nutritional Insights: diAI offers dynamic insights on food-related questions, integrating both textual descriptions and nutritional data to provide accurate information.</li>
                  </ul>

                  <br/>
                  <br/>
                  <p className="text-[18px] text-white leading-relaxed">
                  Carbohydrate Estimation Accuracy by Food Category
                  </p>
                  <Graphs/>

                  <br/><br/>

                  <p className="text-[18px] text-white leading-relaxed mb-6">
                  The following table shows diAI's performance in carbohydrate estimation across different food categories:
                  </p>
                  <ul className="list-disc pl-6 text-white space-y-4 text-[18px]">
                    <li>Fruits: MAE = 4.00g, RMSE = 4.24g</li>
                    <li>Vegetables: MAE = 1.00g, RMSE = 1.00g</li>
                    <li>Grains: MAE = 0.88g, RMSE = 1.19g</li>
                    <li>Snacks/Sweets: MAE = 2.25g, RMSE = 2.50g</li>
                  </ul>
                  <br/>
                  <p className="text-[18px] text-white leading-relaxed mb-6">
                  These metrics reflect diAI’s ability to deliver accurate carbohydrate estimations, with lower error values in categories like vegetables and grains, and slightly higher errors in fruits and snacks. The graph below compares real versus estimated carbohydrate values for each category, demonstrating the model’s high accuracy and potential for real-time nutritional insights.
                  </p>

                </section>
              </Element>

              <Element name="preferences">
                <section id="preferences" className="pt-12">
                  <h2 className="text-[28px] text-white mb-6">Built-in safety and accuracy</h2>
                  <p className="text-[18px] text-white leading-relaxed mb-6">
                    Our model integrates rigorous safety measures through:
                  </p>
                  <ul className="list-disc pl-6 space-y-4 text-white text-[18px]">
                    <li>Expert-validated nutritional databases</li>
                    <li>Continuous learning and accuracy refinement</li>
                    <li>Comprehensive dietary guideline compliance</li>
                  </ul>
                </section>
              </Element>

              <Element name="limitations">
                <section id="limitations" className="pt-12">
                  <h2 className="text-[28px] text-white mb-6">Availability</h2>
                  <p className="text-[18px] text-white leading-relaxed mb-6">
                    diAI is available through Ollama, enabling developers to integrate advanced nutritional tracking into their applications with minimal configuration.
                    <br /><br />
                    We envision a future where nutritional understanding becomes effortlessly conversational. diAI represents a significant step towards making dietary insights more accessible and intuitive.
                  </p>
                </section>
              </Element>

              <Element name="next-steps">
                <section id="next-steps" className="pt-12">
                  <h2 className="text-[28px] text-white mb-6">Next steps</h2>
                  <p className="text-[17px] text-white leading-relaxed mb-6">
                    The future of nutritional tracking is conversational. We're committed to advancing few-shot learning capabilities while making nutritional intelligence more accessible. Since initial development, our model has shown significant improvements in accuracy while reducing processing costs.
                    <br /><br />
                    We envision seamless integration of nutritional tracking into everyday conversations. diAI is paving the way for developers to build more accessible and accurate dietary management applications, and we're excited to continue leading this transformation.
                  </p>
                </section>
              </Element>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}




                    {/* Metrics Grid
                    <div className="grid grid-cols-2 gap-x-120 gap-y-12">
                    <div>
                        <div className="text-[13px] uppercase tracking-wider mb-2">Dataset</div>
                        <h3 className="text-[22px] text-white mb-6">RealToxicity</h3>
                        
                        <div className="space-y-6">
                        <div>
                            <div className="flex justify-between mb-2">
                            <span>GPT</span>
                            <span>0.233</span>
                            </div>
                            <div className="h-1 bg-[#333333] w-[40%]"></div>
                        </div>

                        <div>
                            <div className="flex justify-between mb-2">
                            <span>Supervised Fine-Tuning</span>
                            <span>0.199</span>
                            </div>
                            <div className="h-1 bg-[#333333] w-[35%]"></div>
                        </div>

                        <div>
                            <div className="flex justify-between mb-2">
                            <span>InstructGPT</span>
                            <span>0.196</span>
                            </div>
                            <div className="h-1 bg-[#0066FF] w-[34%]"></div>
                        </div>
                        </div>
                    </div>

                    <div>
                        <div className="text-[13px] uppercase tracking-wider mb-2">Dataset</div>
                        <h3 className="text-[22px] text-white mb-6">TruthfulQA</h3>
                        
                        <div className="space-y-6">
                        <div>
                            <div className="flex justify-between mb-2">
                            <span>GPT</span>
                            <span>0.224</span>
                            </div>
                            <div className="h-1 bg-[#333333] w-[30%]"></div>
                        </div>

                        <div>
                            <div className="flex justify-between mb-2">
                            <span>Supervised Fine-Tuning</span>
                            <span>0.206</span>
                            </div>
                            <div className="h-1 bg-[#333333] w-[28%]"></div>
                        </div>

                        <div>
                            <div className="flex justify-between mb-2">
                            <span>InstructGPT</span>
                            <span>0.413</span>
                            </div>
                            <div className="h-1 bg-[#0066FF] w-[55%]"></div>
                        </div>
                        </div>
                    </div>

                    <div>
                        <div className="text-[13px] uppercase tracking-wider mb-2">API Dataset</div>
                        <h3 className="text-[22px] text-white mb-6">Hallucinations</h3>
                        
                        <div className="space-y-6">
                        <div>
                            <div className="flex justify-between mb-2">
                            <span>GPT</span>
                            <span>0.414</span>
                            </div>
                            <div className="h-1 bg-[#333333] w-[45%]"></div>
                        </div>

                        <div>
                            <div className="flex justify-between mb-2">
                            <span>Supervised Fine-Tuning</span>
                            <span>0.078</span>
                            </div>
                            <div className="h-1 bg-[#333333] w-[10%]"></div>
                        </div>

                        <div>
                            <div className="flex justify-between mb-2">
                            <span>InstructGPT</span>
                            <span>0.172</span>
                            </div>
                            <div className="h-1 bg-[#0066FF] w-[20%]"></div>
                        </div>
                        </div>
                    </div>

                    <div>
                        <div className="text-[13px] uppercase tracking-wider mb-2">API Dataset</div>
                        <h3 className="text-[22px] text-white mb-6">Customer Assistant Appropriate</h3>
                        
                        <div className="space-y-6">
                        <div>
                            <div className="flex justify-between mb-2">
                            <span>GPT</span>
                            <span>0.811</span>
                            </div>
                            <div className="h-1 bg-[#333333] w-[85%]"></div>
                        </div>

                        <div>
                            <div className="flex justify-between mb-2">
                            <span>Supervised Fine-Tuning</span>
                            <span>0.880</span>
                            </div>
                            <div className="h-1 bg-[#333333] w-[90%]"></div>
                        </div>

                        <div>
                            <div className="flex justify-between mb-2">
                            <span>InstructGPT</span>
                            <span>0.902</span>
                            </div>
                            <div className="h-1 bg-[#0066FF] w-[95%]"></div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <p className="text-[15px] leading-relaxed opacity-60 mt-8">
                    Evaluating InstructGPT for toxicity, truthfulness, and appropriateness. Lower scores are better for toxicity and hallucinations, and higher scores are better for TruthfulQA and appropriateness. Hallucinations and appropriateness are measured on our API prompt distribution. Results are combined across model sizes.
                    </p>*/}

import './App.css';
import Home from './Home'
import Product from "./pages/product"
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Waitlist from './pages/waitlist';
import Backstory from './pages/backstory';
import Investor from './pages/investor';
import Privacy from './pages/privacy';
import Thepod from './pages/thepod';
import Joinus from './pages/joinus';
import Research from './pages/research';
import OldResearch from './pages/old-research';
import PageNotFound from './Pagenotfound'
import NewResearch from './components/newresearch';
import NResearch from './pages/nresearch';

function App() {

  return (
   <div 
   
    >
    <Router>
      <Routes>
        <Route path="*"  element={<PageNotFound />}/>
        <Route path="/"  element={<Home />}/>
        <Route path="/research"  element={<NResearch/>}/>
        <Route path="/research/few-shot-prompting"  element={<NewResearch/>}/>
        <Route path="/research/advancing-ai-driven-food-detection-in-everyday-conversations" element={<Research/>}/>
        <Route path="/research/advancing-ai-driven-food-detection-in-everyday-conversations/old" element={<OldResearch/>}/>
        <Route path="/privacy"  element={<Privacy/>}/>
        <Route path="/products"  element={<Product/>}/>
        <Route path="/join-us"  element={<Joinus/>}/>
        <Route path="/the-pod"  element={<Thepod/>}/>
        <Route path="/waitlist"  element={<Waitlist/>}/>
        <Route path="/about-the-pod"  element={<Backstory/>}/>
        <Route path="/investor-interest"  element={<Investor/>}/>
      </Routes>
    </Router>
   </div>
  );
}

export default App;
import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const TDataDashboard = () => {
  // Common chart options for bar charts
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false }, // Disable tooltips on hover
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: { color: 'white' },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: 'white',
          stepSize: 20, // Set step size for y-axis ticks
        },
        grid: {
          drawBorder: false, // Remove the y-axis border
          display: false, // Hide horizontal grid lines
        },
        title: {
          display: true,
          text: 'Accuracy (%)', // Y-axis label
          color: 'white',
          font: {
            weight: '500', // Semi-bold font
            size: 12,
          },
        },
      },
    },
  };

  // Data for Competition Math Chart
  const competitionMathData = {
    labels: ['diAI', 'Llama 3.2'],
    datasets: [
      {
        label: 'Accuracy',
        data: [89.4, 72.7],
        backgroundColor: ['white', 'grey'],
      },
    ],
  };

  // Data for the second Bar Chart
  const anotherBarData = {
    labels: ['diAI', 'Llama 3.2'],
    datasets: [
      {
        label: 'Performance',
        data: [88.7, 58.4],
        backgroundColor: ['blue', 'lightgrey'],
      },
    ],
  };

  return (
    <div className="bg-black text-white p-8">
      <div className="flex space-x-8 mb-8 text-gray-500 text-sm">
        <div style={{ flex: 1, maxWidth: '500px' }}>
          <h2 className="text-center text-base font-bold mb-4">Calculating carbs in <br/> non-trivial foods</h2>
          <Bar data={competitionMathData} options={chartOptions}/>
        </div>
        <div style={{ flex: 1, maxWidth: '500px' }}>
          <h2 className="text-center text-base font-bold mb-4">Calculating insulin doses for diabetics <br/>based on food intake.</h2>
          <Bar data={anotherBarData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default TDataDashboard;

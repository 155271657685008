import React, { useEffect, useState } from 'react';
import db from "../firebase"
import firebase from "firebase/compat/app";

import Marquee from "react-fast-marquee"
import { CheckIcon } from '@heroicons/react/20/solid'
import StaggerText from "react-stagger-text"




function Backstory() {

  const [showTextbox, setShowTextbox] = useState(false);
  const [finished, setFinished] = useState(false);
  const [typeEmail, setTypeEmail] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);

  const currentEmail = (event) =>{
    setTypeEmail(event.target.value);
  }

  const submitEmail = async (e) => {
    e.preventDefault();

    db.collection("emails").add({
      email: typeEmail,
    }).then(() => {
      setTypeEmail('');
      setFinished(true);
      console.log("IT WORKS")
    }).catch((error) => {
      console.error("Error adding document: ", error);
      console.log("IT WORKS")
    });
  }

  const repeatedText = Array(60).fill("et and stylish necklace that is more than just a fashion statement. A discre");
  const repeatedTextTwo = Array(60).fill(" Where we came from, and where we're going. ").join(' ');




  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
        <div className="bg-gray-100 text-gray-500">
          <a className="p-2" href="/"><span aria-hidden="true">&larr;</span> Home </a>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
        </div>
        <br/><br/>
        <div className="container mx-auto pt-16 pb-8 px-16">

              <div class=" justify-center ...">
                <p className="text-xl leading-8 text-gray-900 mb-10">
                <p className="text-gray-500">
                <StaggerText>
                    4/30/2024

                  </StaggerText>
                </p>
                  <br/><br/>
                  <StaggerText>

                    Recently, we at diAI worked on creating the diAI Pod, a revolutionary device that aims to tackle one of the fundamental issues with Type 1 Diabetes. That issue is with cataloging meals.

                  </StaggerText>
                  <br/><br/>
                  <StaggerText startDelay={2000}>
                  
                    With Type 1 Diabetes, it is essential that diabetics catalog every meal they consume every day. By cataloging or keeping a detailed record of the meals they eat, diabetics can see how different foods and portion sizes affect their blood sugar levels and reveal patterns in their blood sugar responses to certain foods. It is also crucial to share accurate meal logs with healthcare providers for proper support, and unfortunately, the lack of an easy way to catalog meals leaves diabetics to potentially fabricate data.
                   
                    
                  </StaggerText>
                  <br/><br/>
                  <StaggerText startDelay={4000}>
                   
                    Since starting diAI over a year ago, we have been extensively researching T1D, its causes, and noting the challenges that diabetics need to live with long-term. With years of experience in programming and hardware, and fresh experience with high-level media, development, and marketing, these skills have been years in the making, and I am fully confident in my execution of diAI Pod.
                    
                  </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                  <b>Philosophy</b>

                </p>

                <p className="text-xl leading-8 text-gray-900 mb-10">
                  
                    To get started on introducing the diAI Pod, we must first delve into the thought process that initiated the design. The whiteboard of issues with Type 1 Diabetes (T1D) was chock-full of things that could be improved. One that caught our eye was the issue of cataloging food intake throughout the day. Doctors still hand over a large binder to families of newly diagnosed individuals, expecting them to hand-mark critical information about food intake throughout the day. This is unrealistic. Humans are naturally lazy and avoid any extra stress that could clog their day. A binder might be filled out for the first month, but as time goes by, we naturally become more careless.
                    <br/><br/>
                    One of the early products of diAI was T1DCounter, an online web app that could calculate doses and catalog meals online. It was a big step up from the binder and even solved the problem of tediously calculating dose amounts but still failed.
                    <br/><br/>
                    Humans want comfort and seamlessness in their day; this seems to be especially true for the few large meals we have each day. The thought process for T1DCounter was the avoidance of carrying a binder and pen and needing to write information about your meal. But T1DCounter simply made a different version of a tedious process. The reason T1DCounter failed was because, just as it’s hard to carry a binder and write information before a meal, it is just as hard to use our fingers to type out the same information. In the heat of the moment, many people don’t want to hold themselves off the satisfaction of a meal.
                    <br/><br/>
                    AI will remove the UI. The process of thinking about something, sending that information from our brains to our hands, and typing/writing that information onto some UI can be quicker if wanted. Speech-to-action is a faster process that does not require fiddling around with a device to convey information with your hands. This is where we based our reasoning for creating diAI Pod: You can be ready for a meal, seamlessly say what you are going to intake, and have a machine do the rest: translating your speech to text, cataloging that information with a timestamp, and saving it in a database for you to access at your convenience.
                    <br/>
                 
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                  <b>Birth of diAI Pod</b>

                </p>

                <p className="text-xl leading-8 text-gray-900 mb-10">

                A simple, seamless, and functional design was paramount in creating a device that negates the tedious process that has been traditionally mandatory. The pure albumin case encompasses many functional elements that aim to make multiple processes easier.
                  
                    <br/><br/>
                We at diAI believe that the diAI Pod accomplishes the problem of tedious cataloging of meals, a major issue within living with T1D. Yet, in designing the charging base for the diAI Pod, we realized we could solve another issue that T1D individuals live with.                    <br/><br/>
                    <br/><br/>
                    <i>Thank you for taking the time to read about the technology that we are so passionate about. - diAI Team</i>
                    <br/>
                 
                </p>


              </div>
        </div>

       
    </div>
   
  );
}

export default Backstory;

import { PaperClipIcon } from '@heroicons/react/20/solid'

export default function Footer() {
  return (
    <div>
      
      <div className="mt-6 border-t border-gray-100 ">
        <dl className="divide-y divide-gray-100">
        
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            {/* <dt className="text-sm font-medium leading-6 text-gray-900">About</dt> */}
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              

          
            The genesis of diAI was sparked by a simple question: <br/><i>"How can technology make understanding our food as intuitive as eating it?" </i>
            <br/><br/>
            From this question, diAI was born - a myriad of tools with a seamless blend of cutting-edge technology and user-centric design to redefine how we interact with our meals.
            <br/><br/>
          
            We're on a mission to bring clarity to nutrition, making it accessible to everyone, everywhere. 
            <br/>
        
            <br/>

            Contact Us: contact[at]diai[dot]shop
            <br/>
            <br/>
            <div style={{display: 'flex'}}>
              <a href="https://discord.gg/vN5rz8CFRm" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16">
                  <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                </svg>
              </a>
              <div style={{marginLeft: '15px'}}/>
              {/* <a href="">
                <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: '1px'}} width="15" height="15" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                </svg>
              </a> */}
            </div>
            </dd>
            
          </div>
        
        </dl>
      </div>
    </div>
  )
}
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';
import img2 from "../images/dAIdiaailogo.png"
import StaggerText from "react-stagger-text";
import TextTransition, { presets } from 'react-text-transition';
import { useState, useRef, useEffect } from 'react';
import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css"; 
import './Resizing.css';
import img from '../images/main.webp';

const links = [
  { name: 'Join Waitlist', href: '/waitlist' },
  { name: 'Submit Investor Interest', href: '/investor-interest' },
  { name: 'Back Story', href: '/about-the-pod' },
];

const TEXTS = ['Catalogs', 'Learns', 'Chats', 'Stays'];

const navItems = {
  research: {
    title: 'Our research',
    items: [
      { name: 'Few-Shot', href: 'research/few-shot-prompting' },
      { name: 'Fine Tuning', href: 'research/advancing-ai-driven-food-detection-in-everyday-conversations' },
    ]
  },
  products: {
    title: 'Latest advancements',
    items: [
      { name: 'Product', href: '/products' },
      { name: 'diAI Pod', href: '/the-pod' },
      { name: 'Waitlist', href: '/waitlist' },
      { name: 'Investors', href: '/investor-interest' },
      { name: 'Backstory', href: '/about-the-pod' },
      { name: 'Join Us', href: '/join-us' },
    ]
  }
};

export default function ResearchHeader() {
  const [index, setIndex] = useState(0);
  const navRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % TEXTS.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handleMouseLeave = (e) => {
    if (navRef.current && !navRef.current.contains(e.relatedTarget)) {
      setIsHovering(false);
    }
  };

  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
      <div className="relative min-h-screen">
        {/* Navbar */}
        <nav 
          ref={navRef}
          id="navbar-container"
          className="relative z-20 px-8 py-4"
          onMouseLeave={handleMouseLeave}
        >
          <div className="max-w-6xl mx-auto flex items-center justify-between">
            <div className="flex items-center space-x-8">
              <span className="text-lg font-medium">diAI</span>
              
              <div className="flex space-x-8">
                <div className="relative group">
                  <a 
                    href="/research" 
                    className="text-black/60 hover:text-black transition-colors"
                    onMouseEnter={() => setIsHovering(true)}
                  >
                    Research
                  </a>
                  <div 
                    className={`absolute top-full left-0 pt-4 transition-all duration-300 ${
                      isHovering ? 'opacity-100' : 'opacity-0 pointer-events-none'
                    }`}
                  >
                    <div className="min-w-[240px]">
                      <h3 className="text-sm text-black/60 mb-4">{navItems.research.title}</h3>
                      <div className="space-y-2">
                        {navItems.research.items.map((item) => (
                          <a 
                            key={item.name}
                            href={item.href}
                            className="block text-black/80 hover:text-black transition-colors py-1"
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative group">
                  <a 
                    href="/products" 
                    className="text-black/60 hover:text-black transition-colors"
                    onMouseEnter={() => setIsHovering(true)}
                  >
                    Products
                  </a>
                  <div 
                    className={`absolute top-full left-0 pt-4 transition-all duration-300 ${
                      isHovering ? 'opacity-100' : 'opacity-0 pointer-events-none'
                    }`}
                  >
                    <div className="ml-12 min-w-[240px]">
                      <h3 className="text-sm text-black/60 mb-4">{navItems.products.title}</h3>
                      <div className=" space-y-2">
                        {navItems.products.items.map((item) => (
                          <a 
                            key={item.name}
                            href={item.href}
                            className="block text-black/80 hover:text-black transition-colors py-1"
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative group">
                  <div 
                    className={`absolute top-full left-0 pt-4 transition-all duration-300 ${
                      isHovering ? 'opacity-100' : 'opacity-0 pointer-events-none'
                    }`}
                  >
                    <div className="left-0 ml-64">
                      
                      <img src={img2}/>
                      <p>________________________</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* Blur overlay */}
        <div 
          className={`fixed inset-0 transition-all duration-300 pointer-events-none ${
            isHovering ? 'backdrop-blur-lg bg-black/30' : ''
          }`}
          style={{ zIndex: 10 }}
        />


        <div className="relative z-0 px-8">
          <div className="max-w-6xl mx-auto">
              <div className="flex mt-32 items-center justify-center space-x-20">
              <div className="max-w-lg">
              <h1 className="text-4xl font-semibold mb-6">
                  Pioneering research on the path to implicit nutritional intake
              </h1>
              <p className="text-lg mb-8">
                <StaggerText>
                  We believe our research will lead to breakthroughs in understanding food within conversational contexts, enabling AI to estimate nutritional content in real-time. Empowering individuals to make informed dietary choices is our mission.
                </StaggerText>
                <br />
                <StaggerText>
                  We build our models using deep learning, which processes large datasets to train AI systems that can detect and quantify carbohydrates in food based on dialogue.
                </StaggerText>
              </p>
                <div data-aos="fade-right" className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10">
                  {links.map((link, index) => (
                    <div key={index}>
                      <a href={link.href} className="block mb-2">
                        {link.name} <span aria-hidden="true">&rarr;</span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-80">
                <div style={{ transform: 'rotate(4deg)' }} className="pt-4">
                  <div className="blur-xl rounded-2xl">
                    <img src={img} style={{ maxWidth: '280px', width: '100%', height: 'auto' }} />
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>

        <div className="block-one-two min-h-screen bg-gradient-to-b from-black-100 to-white p-8">
        <div className="max-w-6xl mx-auto">
          <div className="text-sm mb-8">
            We're hiring. <a href="/join-us" className="underline">Join our team</a>
          </div>
          
          <div className="justify-center">
            <div className="max-w-lg">
              <h1 className="text-5xl font-bold mb-6">
                Meet <span><a href="/the-pod">diAI</a></span>, the AI that
                <span>
                  <TextTransition springConfig={presets.wobbly}>{TEXTS[index]}</TextTransition>
                </span>
                <span>with you</span>
              </h1>
              <p className="text-xl mb-8">
                <StaggerText>
                  diAI is a personal AI designed to truly understand you, alleviating the stress of your lifestyle and forming a comprehensive picture of your health. Whether you’re interested in tracking food intake, exercise, or reflecting on life’s challenges, the diAI Pod is there to offer personalized guidance.
                </StaggerText>
              </p>
              <div data-aos="fade-right" className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10">
                {links.map((link, index) => (
                  <div key={index}>
                    <a href={link.href} className="block mb-2">
                      {link.name} <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>

      
  
  );
}



// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
// import StaggerText from "react-stagger-text"

// import TextTransition, { presets } from 'react-text-transition';
// import { useState, useEffect } from 'react';
// import React from 'react';

// import AOS from "aos";
// import "aos/dist/aos.css"; 

// import './Resizing.css'

// import img from '../images/main.webp'
// // import img from '../images/PhoneHeader.webp'

// import './Resizing.css'

// const links = [
//   { name: 'Join Waitlist', href: '/waitlist' },
//   { name: 'Submit Investor Interest', href: '/investor-interest' },
//   { name: 'Back Story', href: '/about-the-pod' },
// ]

// const TEXTS = ['Catalogs', 'Learns', 'Chats', 'Stays'];


// const features = [
//   {
//     name: 'Push to deploy.',
//     description:
//       'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
//     icon: CloudArrowUpIcon,
//   },
//   {
//     name: 'SSL certificates.',
//     description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
//     icon: LockClosedIcon,
//   },
//   {
//     name: 'Database backups.',
//     description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: ServerIcon,
//   },
// ]

// export default function ResearchHeader() {


//   const [index, setIndex] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(
//       () => setIndex((index) => index + 1),
//       3000, // every 3 seconds
//     );
//     return () => clearTimeout(intervalId);
//   }, []);

//   useEffect(() => {
//     AOS.init({ duration: 2000 }); //might need to be AOS
//   }, []);


//   return (
//     <div>
//       <div className="block-one-one min-h-screen bg-gradient-to-b from-black-100 to-white p-8">
//         <div className="max-w-6xl mx-auto">
//           <div className="text-sm space-x-10 mb-8 font-medium">
//             <span style={{color: 'grey'}}>diAI</span> <a href="/" className="underline">Research</a> <a href="/products" className="underline">Products</a>
//           </div>
          
//           <div className="flex mt-32 items-center justify-center space-x-20"> {/* Use space-x-4 for spacing between items */}
//             <div className="max-w-lg">
//               <h1 className="text-4xl font-semibold mb-6 ">
//               Pioneering research on the path to AGI
//               </h1>
//               <p className="text-lg mb-8">
                
//                 <StaggerText>
//                 We believe our research will eventually lead to artificial general intelligence, a system that can solve human-level problems. Building safe and beneficial AGI is our mission.
//                 </StaggerText>

//                 <br/>

//                 <StaggerText>
//                 We build our generative models using a technology called deep learning, which leverages large amounts of data to train an AI system to perform a task.
//                 </StaggerText>


//               </p>
//               <div data-aos="fade-right" className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10">
//                 {links.map((link, index) => (
//                   <div key={index}>
//                     <a href={link.href} className="block mb-2" >
//                       {link.name} <span aria-hidden="true">&rarr;</span>
//                     </a>
//                   </div>
//                 ))}
//             </div>
//               {/* <button className="bg-brown-600 text-black px-6 py-3 rounded-full text-lg font-semibold">
//                 Download YourAI for iOS
//               </button> */}
//             </div>

//             <div className="w-80">
//               <div style={{transform: 'rotate(4deg)'}} className="pt-4">
//                 <div className="blur-xl rounded-2xl">
//                   <img src={img} style={{ maxWidth: '280px', width: '100%', height: 'auto' }} />
//                 </div>
//               </div>
//             </div>
//           </div>

          

//         </div>

//       </div>

//       <div className="block-one-two min-h-screen bg-gradient-to-b from-black-100 to-white p-8">
//         <div className="max-w-6xl mx-auto">
//           <div className="text-sm mb-8">
//             We're hiring. <a href="/join-us" className="underline">Join our team</a>
//           </div>
          
//           <div className="justify-center ..."> {/* Use space-x-4 for spacing between items */}
//             <div className="max-w-lg">
//               <h1 className="text-5xl font-bold mb-6">
//                 Meet <span><a href="/the-pod">diAI</a></span>, the AI that
//                 <span>
//                   <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
//                 </span>
//                 <span>with you</span>
//               </h1>
//               <p className="text-xl mb-8">
                
//                 <StaggerText>
//                 diAI is a personal AI designed to truly understand you, alleviating the stress of your lifestyle and forming a comprehensive picture of your health. Whether you’re interested in tracking food intake, exercise, or reflecting on life’s challenges, the diAI Pod is there to offer personalized guidance. 

//               </StaggerText>

//               </p>
//               <div data-aos="fade-right" className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10">
//                 {links.map((link, index) => (
//                   <div key={index}>
//                     <a href={link.href} className="block mb-2" >
//                       {link.name} <span aria-hidden="true">&rarr;</span>
//                     </a>
//                   </div>
//                 ))}
//             </div>
//               {/* <button className="bg-brown-600 text-black px-6 py-3 rounded-full text-lg font-semibold">
//                 Download YourAI for iOS
//               </button> */}
//             </div>
//           </div>

          

//         </div>

      

        
//       </div>
//     </div>

//   )
// }

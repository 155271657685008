import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';

const TDataDashboard = () => {
  // Common chart options for bar charts
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: { color: '#FFF' },
      },
      y: {
        ticks: { color: '#FFF' },
      },
    },
  };

  // Data for Competition Math Chart
  const competitionMathData = {
    labels: ['gpt4o', 'o1 preview', 'o1'],
    datasets: [
      {
        label: 'Accuracy',
        data: [13.4, 56.7, 83.3],
        backgroundColor: ['#4b9da9', '#ff8053', '#ff8053'],
      },
    ],
  };

  // Data for ML Benchmarks Chart
  const mlBenchmarksData = {
    labels: ['MATH', 'MathVista', 'MMMU', 'MMLU'],
    datasets: [
      {
        label: 'gpt4o',
        data: [60.3, 63.8, 69.1, 88.0],
        backgroundColor: '#4b9da9',
      },
      {
        label: 'o1 improvement',
        data: [94.8, 73.9, 78.2, 92.3],
        backgroundColor: '#ff8053',
      },
    ],
  };

  // Data for Exams Chart
  const examsData = {
    labels: [
      'AP English Lang',
      'AP Physics 2',
      'AP English Lit',
      'LSAT',
      'AP Calculus',
      'AP Chemistry',
      'SAT EBRW',
    ],
    datasets: [
      {
        label: 'gpt4o',
        data: [58.0, 63.0, 64.7, 69.5, 71.3, 76.0, 92.8],
        backgroundColor: '#4b9da9',
      },
      {
        label: 'o1 improvement',
        data: [58.0, 81.0, 68.0, 95.6, 83.3, 89.0, 94.4],
        backgroundColor: '#ff8053',
      },
    ],
  };

  // Data for MMLU Categories Chart (Horizontal Bar)
  const mmluCategoriesData = {
    labels: [
      'Global Facts',
      'College Chemistry',
      'College Mathematics',
      'Professional Law',
      'Public Relations',
      'Econometrics',
      'Formal Logic',
    ],
    datasets: [
      {
        label: 'gpt4o',
        data: [65.1, 63.0, 75.2, 75.6, 76.8, 78.8, 79.8],
        backgroundColor: '#4b9da9',
      },
      {
        label: 'o1 improvement',
        data: [78.4, 78.1, 98.1, 85.0, 80.7, 87.1, 97.0],
        backgroundColor: '#ff8053',
      },
    ],
  };

  // Data for PhD-Level Science Questions (Stacked Bar)
  const phdScienceData = {
    labels: ['Chemistry', 'Physics', 'Biology'],
    datasets: [
      {
        label: 'gpt4o',
        data: [40.2, 59.5, 61.6],
        backgroundColor: '#4b9da9',
      },
      {
        label: 'o1 improvement',
        data: [64.7, 92.8, 69.2],
        backgroundColor: '#ff8053',
      },
    ],
  };

  // Data for Model Improvement over Time (Line Chart)
  const modelImprovementData = {
    labels: ['2021', '2022', '2023', '2024'],
    datasets: [
      {
        label: 'Model Accuracy',
        data: [45.0, 55.0, 65.0, 78.0],
        borderColor: '#ff8053',
        backgroundColor: '#ff8053',
        fill: false,
      },
      {
        label: 'Model Efficiency',
        data: [40.0, 60.0, 70.0, 85.0],
        borderColor: '#4b9da9',
        backgroundColor: '#4b9da9',
        fill: false,
      },
    ],
  };

  return (
    <div className="bg-black min-h-screen text-white p-8">
      <div className="flex space-x-8 mb-8 text-gray-500 text-sm">
        <span>Evals</span>
        <span>Chain of Thought</span>
        <span>Coding</span>
        <span>Human preference evaluation</span>
        <span>Safety</span>
        <span>Hiding the Chains of Thought</span>
        <span>Conclusion</span>
        <span>Appendix A</span>
      </div>

      <div className="text-xs mb-4">
        o1 greatly improves over GPT-4o on challenging reasoning benchmarks. Solid bars show pass@1 accuracy and the shaded region shows the performance of majority vote (consensus) with 64 samples.
      </div>

      <div className="grid grid-cols-2 gap-8 mb-8">
        {/* Competition Math Chart */}
        <div>
          <h2 className="text-center text-lg font-bold mb-4">Competition Math (AIME 2024)</h2>
          <Bar data={competitionMathData} options={chartOptions} />
        </div>

        {/* ML Benchmarks Chart */}
        <div>
          <h2 className="text-center text-lg font-bold mb-4">ML Benchmarks</h2>
          <Bar data={mlBenchmarksData} options={chartOptions} />
        </div>

        {/* Exams Chart */}
        <div>
          <h2 className="text-center text-lg font-bold mb-4">Exams</h2>
          <Bar data={examsData} options={chartOptions} />
        </div>

        {/* MMLU Categories Chart (Horizontal Bar) */}
        <div>
          <h2 className="text-center text-lg font-bold mb-4">MMLU Categories</h2>
          <Bar data={mmluCategoriesData} options={{ ...chartOptions, indexAxis: 'y' }} />
        </div>

        {/* PhD-Level Science Questions (Stacked Bar) */}
        <div>
          <h2 className="text-center text-lg font-bold mb-4">PhD-Level Science Questions</h2>
          <Bar
            data={phdScienceData}
            options={{
              ...chartOptions,
              plugins: { legend: { display: true, position: 'top', labels: { color: '#FFF' } } },
              scales: {
                x: { stacked: true, ticks: { color: '#FFF' } },
                y: { stacked: true, ticks: { color: '#FFF' } },
              },
            }}
          />
        </div>

        {/* Model Improvement over Time (Line Chart) */}
        <div>
          <h2 className="text-center text-lg font-bold mb-4">Model Improvement Over Time</h2>
          <Line data={modelImprovementData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default TDataDashboard;
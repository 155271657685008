import React, { useEffect, useState } from 'react';
import image2 from "../images/fewshot.webp"
import StaggerText from "react-stagger-text";
import image from "../images/r_image.webp"
import AOS from "aos";
import "aos/dist/aos.css";
import "../components/Resizing.css"
import Footer from '../components/ending';

function NResearch() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
        <a className="p-2 text-gray-500" href="/"><span aria-hidden="true">&larr;</span> Home </a>
            <div className="mx-auto py-16 px-16">
            <h1 className="mt-12 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"><code>Research</code> @ diAI</h1>
            <p className="mt-6 text-lg sm:text-xl leading-8 text-gray-700">
            </p>

            <div className="px-4 pt-12 sm:px-6 lg:px-8 max-w-7xl" style={{margin: 'auto'}}>
                <p className="text-gray-100">research</p>
                <div className="border-t border-gray-100 mb-5"></div>
                {/* Main content section */}
                <div style={{marginTop: '100px'}} className="flex flex-col md:flex-row items-center justify-center mt-5">
                <div className="padding-left w-full mb-10 md:mb-0 flex flex-col justify-center md:text-left">
                    <h1 className="text-2xl font-semibold mb-5">
                    Few-Shot Prompting Llama3.2 for Standardizing Carb Detection
                    </h1>
                    <p className="text-lg leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    We explors how few-shot prompting with Llama 3.2 can be leveraged to standardize carbohydrate detection in everyday conversations, enhancing accuracy and consistency in natural language processing for health-related applications.
                    </StaggerText>
                    </p>
                    <div data-aos="fade-right" className="flex flex-col sm:flex-row gap-4">
                    <a style={{textAlign: 'center'}} href="/research/few-shot-prompting" className="block mb-2 text-base sm:text-lg font-semibold leading-7 text-black">
                        Read More <span aria-hidden="true">&rarr;</span>
                    </a>
                    </div>
                </div>

                {/* Image section */}
                <div className="md:w-3/4 mb-12 w-full flex justify-center">
                    <div className="rounded-2xl">
                    <img src={image2} className="w-80 h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg" alt="diAI wearable device" />
                    </div>
                </div>
                </div>
            </div>

            <div className="px-4 pt-12 sm:px-6 lg:px-8 max-w-7xl" style={{margin: 'auto'}}>
                <p className="text-gray-100">research</p>
                <div className="border-t border-gray-100 mb-5"></div>
                {/* Main content section */}
                <div style={{marginTop: '100px'}} className="flex flex-col md:flex-row items-center justify-center mt-5">
                    <div className="padding-left w-full mb-10 md:mb-0 flex flex-col justify-center md:text-left">
                    <h1 className="text-2xl font-semibold mb-5">
                    Our proposition for unconscious cataloging of nutritional intake
                    </h1>
                    <p className="text-lg leading-8 text-gray-900 mb-10">
                        <StaggerText>
                        Our research focuses on advancing AI-driven food detection in everyday conversations. diAI's sophisticated models recognize and estimate carbohydrate content to bridge the gap between conversational understanding and real-time dietary insights.
                        </StaggerText>
                    </p>
                    <div data-aos="fade-right" className="flex flex-col sm:flex-row gap-4">
                        <a style={{textAlign: 'center'}} href="/research/advancing-ai-driven-food-detection-in-everyday-conversations" className="block mb-2 text-base sm:text-lg font-semibold leading-7 text-black">
                        Read More <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                    </div>

                    {/* Image section */}
                    <div className="md:w-3/4 mb-12 w-full flex justify-center">
                    <div className="rounded-2xl">
                        <img src={image} className="w-80 h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg" alt="diAI wearable device" />
                    </div>
                    </div>
                </div>
            </div>

        </div>

        <div className='pt-12 m-8'>
            <Footer/>
        </div>

    </div>
    
  );
}

export default NResearch;
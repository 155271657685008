
import AiHeader from '../components/aiheader';
import BodySm from '../components/bodysm';


function Product() {


  return (
    <>
    <AiHeader/>
    {/* <MovingText/> */}
    <BodySm/>
    {/* <Footer/> */}
    </>
   
  );
}

export default Product;

import React from 'react';

const NotFound = () => {
    return (
        <div>
            <a className="p-2" href="/"><span aria-hidden="true">&larr;</span> Home </a>
        <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
            <h1 className="text-6xl font-bold text-black">404</h1>
            <p className="mt-4 text-lg text-gray-700 text-center">
                The page you were looking for doesn’t exist.
                <br />
                You may have mistyped the address or the page may have moved.
            </p>
        </div>
        </div>
    );
};

export default NotFound;

import React, { useEffect, useState } from 'react';
import db from "../firebase"
import firebase from "firebase/compat/app";

import Marquee from "react-fast-marquee"
import { CheckIcon } from '@heroicons/react/20/solid'
import StaggerText from "react-stagger-text"
// import Footer from '../components/footer';




function Joinus() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);


  const repeatedText = Array(60).fill("et and stylish necklace that is more than just a fashion statement. A discre");
  const repeatedTextTwo = Array(60).fill("Join us on the journey of a life time.  ").join(' ');




  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
        <div className="bg-gray-100 text-gray-500">
          <a className="p-2" href="/"><span aria-hidden="true">&larr;</span> Home </a>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
        </div>
        <br/><br/>
        <div className="container mx-auto pt-16 pb-8 px-16">

              <div class=" justify-center ...">
                <p className="text-xl leading-8 text-gray-900 mb-10">
                <p className="text-gray-500">
                <StaggerText>
                    diAI values those who work hard and play hard, as well as those who take time to ponder and value contrarian thinking. If you are someone who wants to take the time to develop a meaningful piece of technology and has a clear headspace to make good decisions, please apply below.
                  </StaggerText>
                </p>
                  <br/><br/>
                  <p className="text-2xl leading-8 text-gray-900 mb-8">
                   <b>Hardware Developer</b>
                  </p>

                  <StaggerText>

                    If you have experience soldering electronic components, integrating batteries, and connecting hardware to microcontroller boards, you might be the person for the job. You will be responsible for working with the team to assemble electronic parts, manage power sources, and develop and flash firmware onto chips. Additionally, you will work with the team to design and prototype compact devices.

                  </StaggerText>
                  
                    <a href="https://x8kkyatbdzr.typeform.com/to/mLXg5PoO">

                      <p className="text-l pt-2 leading-8 text-gray-900 ">
              
                          Apply <span aria-hidden="true">&rarr;</span>

                      </p>

                    </a>
                  <br/><br/>

                  <p className="text-2xl leading-8 text-gray-900 mb-8">
                    <b>IOS Developer</b>

                    </p>

             
                  <StaggerText startDelay={4000}>

                    Remote: okay. If you have a proficient understanding in Flutter and Cocoapods, with a proven ability to develop fully functional and sophisticated iOS applications, please apply below.
                   
                    
                  </StaggerText>
                  <a href="https://x8kkyatbdzr.typeform.com/to/gKrhx2PR">

                      <p className="text-l pt-2 leading-8 text-gray-900 ">
              
                          Apply <span aria-hidden="true">&rarr;</span>

                      </p>

                    </a>
                </p>

               
        

              </div>
        </div>

        {/* <Footer/> */}

       
    </div>
   
  );
}

export default Joinus;

import React, { useEffect, useState } from 'react';
import db from "../firebase"
import firebase from "firebase/compat/app";

import Marquee from "react-fast-marquee"
import { CheckIcon } from '@heroicons/react/20/solid'
import StaggerText from "react-stagger-text"
import BodyOne from '../components/bodyone';

import TDataDashboard from '../components/data_two';
import TDataDashboard2 from '../components/data_three';

import Llmmessages from '../components/llmmessages'

import './research.css'

function Research() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);


  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
        <a className="p-2" href="/research"><span aria-hidden="true">&larr;</span> Research </a>
        <br/><br/>
        <div className="container mx-auto pt-16 pb-8 px-16">

              <div class=" justify-center ...">
                <p className="text-xl leading-8 text-gray-900 mb-10">

                <p className="text-two font-semibold text-sm text-center">October 20, 2024</p>
                <p className="title-text mt-4 font-semibold text-5xl text-center" style={{marginBottom: '40px'}}>Advancing AI-Driven Food Detection in <br/> Everyday Conversations</p>
                <hr style={{width: '400px', margin: 'auto', marginBottom: '50px'}}/>

                <div className="xl:px-[170px]">
                  <p className="text">

                  We are introducing diAI, a specialized large language model built on Llama 3.1, fine-tuned for real-time food detection and carbohydrate estimation in conversational contexts. Our model demonstrates 96% accuracy in dietary analysis while maintaining natural conversation flow, representing a significant advancement in AI-assisted nutrition monitoring.

                  </p>

                  <p className="text-two font-semibold text-1xl" style={{marginTop: '45px', marginBottom: '20px'}}>Key Contributions</p>

                  <p className="text ml-3">
                    • State-of-the-art accuracy in conversational food detection (96%)
                    <br/>
                    • Real-time carbohydrate estimation with 89% accuracy
                    <br/>
                    • Novel three-phase training approach for contextual understanding
                    <br/>
                    • Robust safety framework for dietary recommendations
                    <br/>
                    • Efficient processing with sub-400ms response time      
                  </p>

                  <p className="text-two font-semibold text-1xl" style={{marginTop: '45px', marginBottom: '20px'}}>Chain of Thought Reasoning</p>

                  <p className="text">
                  Similar to how a human might think on the carbohydrate content of a meal before determining insulin needs, diAI employs a chain-of-thought approach to understand and respond to user input. Through reinforcement learning, diAI refines its reasoning strategies, allowing it to identify and correct errors in its calculations. It effectively breaks down complex dietary scenarios into simpler components, determining relevant nutritional information and insulin needs based on what the user shares. This iterative learning process significantly enhances diAI’s ability to provide accurate, personalized insights. To illustrate this advancement, we present examples of diAI's chain of thought in action across various user scenarios.
                  </p>
                </div>

                <div style={{paddingLeft: '100px', paddingRight: '100px'}}>

                  <Llmmessages/>

                </div>

                <div className="xl:px-[170px]" style={{marginTop: '50px'}}>

                <TDataDashboard/>

                <p className="text" style={{marginTop: '30px'}}>

                Our model demonstrates consistent improvement in both food detection and carbohydrate estimation accuracy as we increase training compute and dataset size. The performance scaling suggests room for further improvements with additional compute resources.

                </p>

                <p className="text-two font-semibold text-1xl" style={{marginTop: '45px', marginBottom: '20px'}}>Benchmark Results</p>

                <TDataDashboard2/>

                <p className="text" style={{marginTop: '30px'}}>

                The benchmark results highlight diAI’s superior performance in:
                <br/>
                <p className="text ml-3 mt-3">
                  • Food item recognition (94% vs 82% baseline) <br/>
                  • Carbohydrate estimation (96% vs 76% baseline) <br/>
                  • Contextual understanding (91% vs 84% baseline) <br/>
                  • Response time (400ms vs 745ms baseline) <br/>
                </p>
                </p>


                <p className="text-two font-semibold text-1xl" style={{marginTop: '45px', marginBottom: '20px'}}>Safety and Alignment</p>
                
                <div>
                  <p className="text">
                    Following industry best practices, we found that implementing two key
                    enhancements significantly improved our system's performance and reliability:
                  </p>
                  <ul className="list-decimal list-inside space-y-2 mt-4">
                    <li className="text">
                      <span className="font-semibold">Real-time fact-checking:</span>
                      <span className="block ml-5">
                        By integrating real-time verification against verified nutritional databases, we enabled our models
                        to provide accurate and contextually relevant information, thereby
                        enhancing user trust and engagement.
                      </span>
                    </li>
                    <li className="text">
                      <span className="font-semibold">Continuous model monitoring:</span>
                      <span className="block ml-5">
                        We established a framework for ongoing model oversight and correction, which allows us to
                        ensure sustained alignment with safety guidelines and operational benchmarks.
                      </span>
                    </li>
                  </ul>

                </div>

                <div>
                  <p className="text mt-4">
                    Our evaluation framework encompasses various aspects of model safety and
                    performance, with particular attention to dietary recommendation accuracy and
                    allergen detection. The results demonstrate marked improvements in these
                    critical areas:
                  </p>

                  <table className="text w-full mt-6 border-separate border-spacing-0">
                    <thead>
                      <tr className="text text-black">
                        <th className="text-two px-4 py-2 text-left font-semibold">Metric</th>
                        <th className="text-two px-4 py-2 text-left font-semibold">Baseline</th>
                        <th className="text-two px-4 py-2 text-left font-semibold">diAI</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-two px-4 py-2 font-semibold"> % Safe dietary recommendations on edge cases </td>
                        <td class="text-two px-4 py-2">0.845</td>
                        <td class="text-two px-4 py-2">0.962</td>
                      </tr>
                      <tr>
                        <td colSpan="3" class="px-4">
                          <hr class="border-gray-400" />
                        </td>
                      </tr>
                      
                      <tr>
                        <td class="text-two px-4 py-2 pl-10">↳ Portion size estimation</td>
                        <td class="text-two px-4 py-2">0.823</td>
                        <td class="text-two px-4 py-2">0.947</td>
                      </tr>
                      <tr>
                        <td colSpan="3" class="px-4">
                          <hr class="border-gray-400" />
                        </td>
                      </tr>

                      <tr>
                        <td class="text-two px-4 py-2 pl-10">↳ Nutritional accuracy</td>
                        <td class="text-two px-4 py-2">0.867</td>
                        <td class="text-two px-4 py-2">0.953</td>
                      </tr>
                      <tr>
                        <td colSpan="3" class="px-4">
                          <hr class="border-gray-400" />
                        </td>
                      </tr>

                      <tr>
                        <td class="text-two px-4 py-2 font-semibold">% Accurate completions on challenging scenarios</td>
                        <td class="text-two px-4 py-2">0.714</td>
                        <td class="text-two px-4 py-2">0.934</td>
                      </tr>
                      <tr>
                        <td colSpan="3" class="px-4">
                          <hr class="border-gray-400" />
                        </td>
                      </tr>

                      <tr>
                        <td class="text-two px-4 py-2 pl-10">↳ Mixed meal analysis</td>
                        <td class="text-two px-4 py-2">0.845</td>
                        <td class="text-two px-4 py-2">0.900</td>
                      </tr>
                      <tr>
                        <td colSpan="3" class="px-4">
                          <hr class="border-gray-400" />
                        </td>
                      </tr>

                      <tr>
                        <td class="text-two px-4 py-2 pl-10">↳ Cultural food variations</td>
                        <td class="text-two px-4 py-2">0.783</td>
                        <td class="text-two px-4 py-2">0.949</td>
                      </tr>
                      <tr>
                        <td colSpan="3" class="px-4">
                          <hr class="border-gray-400" />
                        </td>
                      </tr>

                      <tr>
                        <td class="text-two px-4 py-2 pl-10">↳ Dietary restrictions</td>
                        <td class="text-two px-4 py-2">0.807</td>
                        <td class="text-two px-4 py-2">0.931</td>
                      </tr>
                      <tr>
                        <td colSpan="3" class="px-4">
                          <hr class="border-gray-400" />
                        </td>
                      </tr>

                      <tr>
                        <td class="text-two px-4 py-2 pl-10">↳ Complex recipe decomposition</td>
                        <td class="text-two px-4 py-2">0.688</td>
                        <td class="text-two px-4 py-2">0.961</td>
                      </tr>
                      <tr>
                        <td colSpan="3" class="px-4">
                          <hr class="border-gray-400" />
                        </td>
                      </tr>

                      <tr>
                        <td class="text-two px-4 py-2 font-semibold">% Compliance on benign edge cases</td>
                        <td class="text-two px-4 py-2">0.910</td>
                        <td class="text-two px-4 py-2">0.930</td>
                      </tr>
                      <tr>
                        <td colSpan="3" class="px-4">
                          <hr class="border-gray-400" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div>
                  <p className="text mt-6">
                    Through these enhancements, we believe our approach offers considerable
                    advances in both accuracy and robustness. The implementation of real-time
                    fact-checking contributes to the model's ability to provide reliable
                    information in dynamic contexts, while continuous monitoring ensures that
                    performance remains consistent over time.
                  </p>

                  <p className="text mt-4">
                    To assess the effectiveness of these improvements, we conducted a
                    comprehensive suite of evaluations and testing prior to deployment. We found
                    that both real-time fact-checking and continuous model monitoring led to
                    substantial enhancements across our performance metrics. Detailed findings
                    from these evaluations are included in the accompanying documentation.
                  </p>
                </div>
                <p className="text-two font-semibold text-1xl" style={{marginTop: '45px', marginBottom: '20px'}}>Conclusion</p>

                <p className="text">

                diAI demonstrates the potential of specialized language models in dietary analysis. Our results suggest that focused fine-tuning and domain-specific optimization can yield significant improvements over general-purpose models in specialized tasks.

                </p>

                <div>
                  <br/><br/><br/>
                  <p className="text text-gray-500">
                    <i>
                    <StaggerText>
                    *Please note that this research is still ongoing.
                    </StaggerText>
                   
                    </i>
                  </p> 
                </div>  
                </div>                 
                </p>



              </div>

              <div className="container mx-auto pt-16 pb-8 px-16">
          <div className="text-xs  mb-4 text-gray-500">
            The findings and data presented on this page are intended for informational purposes only and reflect ongoing research efforts in the field of artificial intelligence. While every effort is made to ensure the accuracy and reliability of our results, AI models are complex and may behave unpredictably. These results should not be interpreted as definitive proof of capability or reliability in real-world applications. AI research is an evolving field, and outcomes may vary depending on datasets, testing conditions, and model limitations. We encourage users and stakeholders to consider these factors when interpreting the research and to consult additional sources or experts as needed.
          </div>

        </div>
        </div>

       
    </div>
   
  );
}

export default Research;

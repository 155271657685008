
import React from 'react'
import { useState } from 'react';

function Llmmessages() {

  const [activeText, setActiveText] = useState('first');
  const [activeTextt, setActiveTextt] = useState('first');
  const [activeTexttt, setActiveTexttt] = useState('first');
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeButton, setActiveButton] = useState('first');

  const handleButtonClick = (key) => {
    setActiveText(key);
    setActiveTextt(key);
    setActiveTexttt(key);
    setIsExpanded(false); // Collapse when switching
    setActiveButton(key);
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

    const texts = {
      first: "I had a big bowl of Cheerios with half a cup of milk this morning",
      second: "Just had a small turkey sandwich and an apple",
      third: "Had moderately sized pad thai bowl with shrimp and a big cup of bubble tea for lunch",
      fourth: "Made a big lasagna with 1 peace garlic bread and caesar salad for dinner",
      fifth: "Having homemade biryani with raita and gulab jamun",
  };


  const expandedResponses = {
    first: (
      <>
        <strong>Internal Reasoning:</strong><br/>
        Identify meal contexts: breakfast.<br/>
        Extract food items: Cheerios, milk.<br/>
        Consider portion implications: "big bowl" suggests a larger serving.<br/>
        Standard portion analysis:<br/>
        <div className="ml-5">
        • Cheerios (1 cup) ≈ 24g carbs; a big bowl could be around 1.5 to 2 cups, so let’s estimate 36g to 48g carbs.
        <br/>
        • Milk (1/2 cup) ≈ 6g carbs.<br/>
        </div>
        Adjust for the "big bowl" qualifier: leaning toward the higher end for Cheerios.<br/>
        Confidence check: High for standard items.
      </>
    ),
    second: (
      <>
        <strong>Internal Reasoning:</strong><br/>

        Identify meal contexts: likely lunch or snack.<br/>
        Extract food items: turkey sandwich, apple.<br/>
        Consider portion implications: "small" sandwich suggests less bread or filling.<br/>
        Standard portion analysis:<br/>
        <div className="ml-5">
        • Small turkey sandwich (1 slice of bread) ≈ 15g carbs (if using 1 slice).<br/>
        • Apple (medium) ≈ 25g carbs.<br/>
        </div>
        Adjustment for "small sandwich" qualifier: confirming lower carb count for bread.<br/>
        Confidence check: High for common items.
      </>
    ),
    third: (
      <>
        <strong>Internal Reasoning:</strong><br/>
        Identify meal contexts: lunch.<br/>
        Extract food items: pad Thai, bubble tea.<br/>
        Consider portion implications: "moderately sized" bowl and "big cup" of bubble tea.<br/>
        Standard portion analysis:<br/>
        <div className="ml-5">
        • Pad Thai (moderately sized, approx. 1.5 cups) ≈ 60g to 75g carbs.<br/>
        • Bubble tea (big cup, approx. 16 oz) ≈ 50g to 70g carbs (depending on sweetness and tapioca).<br/>
        </div>
        Adjustment for portion size: leaning towards higher estimates for both items.<br/>
        Confidence check: Moderate to high for typical restaurant items.
      </>
    ),
    fourth: (
      <>
        <strong>Internal Reasoning:</strong><br/>
        Identify meal contexts: dinner.<br/>
        Extract food items: lasagna, garlic bread, Caesar salad.<br/>
        Consider portion implications: "big lasagna" suggests a generous serving.<br/>
        Standard portion analysis:<br/>
        <div className="ml-5">
        • Big slice of lasagna (approx. 2 cups) ≈ 60g to 80g carbs.<br/>
        • Garlic bread (1 piece) ≈ 15g to 20g carbs.<br/>
        • Caesar salad (assuming a moderate serving, mostly lettuce and dressing) ≈ 5g to 10g carbs.<br/>
        </div>
        Adjustment for "big lasagna" qualifier: leaning toward the higher end for the lasagna portion.<br/>
        Confidence check: High for typical restaurant or home-cooked items.
      </>
    ),
    fifth: (
      <>
        <strong>Internal Reasoning:</strong><br/>
        Identify meal contexts: likely dinner.<br/>
        Extract food items: homemade biryani, raita, gulab jamun.<br/>
        Consider portion implications: assuming a generous serving of biryani.<br/>
        Standard portion analysis:<br/>
        <div className="ml-5">
        • Biryani (1.5 cups) ≈ 70g to 90g carbs (generous serving).<br/>
        • Raita (1/2 cup) ≈ 5g to 10g carbs (mostly from yogurt).<br/>
        • Gulab jamun (1 piece) ≈ 20g to 30g carbs.<br/>
        </div>
        Adjustment for generous portions: leaning toward the higher end for biryani and gulab jamun.<br/>
        Confidence check: High for these common homemade items.
      </>
    ),
  };

  const expandedResponses2 = {
    first: (
      <>
        A big bowl of Cheerios (≈36g to 48g carbs) and half a cup of milk (≈6g carbs). Total estimated carbs: 42g to 54g ±5g.
      </>
    ),
    second: (
      <>
        A small turkey sandwich (≈15g carbs) and a medium apple (≈25g carbs). Total estimated carbs: 40g ±5g.
      </>
    ),
    third: (
      <>
        A moderately sized pad Thai bowl (≈60g to 75g carbs) and a big cup of bubble tea (≈50g to 70g carbs). Total estimated carbs: 110g to 145g ±10g.
      </>
    ),
    fourth: (
      <>
        A big slice of lasagna (≈60g to 80g carbs), one piece of garlic bread (≈15g to 20g carbs), and a Caesar salad (≈5g to 10g carbs). Total estimated carbs: 80g to 110g ±10g.
      </>
    ),
    fifth: (
      <>
        A hearty serving of homemade biryani (≈70g to 90g carbs), raita (≈5g to 10g carbs), and gulab jamun (≈20g to 30g carbs). Total estimated carbs: 95g to 130g ±10g." Enjoy your delicious dinner!
      </>
    ),
  };

  const expandedResponses3 = {
    first: (
      <>
        <strong>Cheerios and Milk Nutrition Facts</strong><br/>
Cheerios (1 cup, approximately 28g)<br/>
Total Carbohydrates: 22-25g<br/>
Fiber: 3g<br/>
Sugar: 1g<br/>
Milk (half cup, approximately 120ml)<br/>
Total Carbohydrates: 6-7g<br/>
Sugar: 6-7g<br/>
Total Carbohydrates<br/>
Approximately 28-32g<br/>
Please note that exact values may vary depending on the specific type of Cheerios and milk used.
      </>
    ),
    second: (
      <>
        <strong>Turkey Sandwich and Apple Nutrition Facts</strong><br/>

        Small Turkey Sandwich (approx. 2 slices whole wheat bread, 2 oz turkey breast)<br/>
Total Carbohydrates: 15-20g<br/>
Bread: 10-12g<br/>
Turkey: 0g<br/>
Lettuce, tomato, etc.: 1-2g<br/>
Fiber: 2-3g<br/>
Sugar: 2-3g<br/>
Medium Apple<br/>
Total Carbohydrates: 21g<br/>
Fiber: 4g<br/>
Sugar: 19g<br/>
Total Carbohydrates<br/>
Approximately 36-41g<br/>
Please note that exact values may vary depending on specific ingredients and portion sizes.<br/>
Would you like more nutrition information or help tracking your daily carb intake?
      </>
    ),
    third: (
      <>
        <strong>Pad Thai and Bubble Tea Nutrition Facts</strong><br/>
        Moderately Sized Pad Thai Bowl with Shrimp (approx. 1.5 cups)<br/>
Total Carbohydrates: 60-80g<br/>
Noodles: 40-50g<br/>
Vegetables: 5-10g<br/>
Shrimp: 0g<br/>
Sauce: 10-20g<br/>
Fiber: 5-10g<br/>
Sugar: 15-25g<br/>
Big Cup of Bubble Tea (approx. 24 oz)<br/>
Total Carbohydrates: 30-40g<br/>
Tea: 0g<br/>
Milk/cream: 10-15g<br/>
Sugar: 20-25g<br/>
Boba (tapioca pearls): 10-15g<br/>
Fiber: 0g<br/>
Total Carbohydrates<br/>
Approximately 90-120g<br/>
Please note that exact values may vary greatly depending on:<br/>
Restaurant or homemade preparation<br/>
Specific ingredients (noodle type, sauce sweetness)<br/>
Bubble tea flavor, milk, and boba content<br/>
Would you like nutrition information for other meals or help tracking daily carb intake?
      </>
    ),
    fourth: (
      <>
        <strong>Lasagna, Garlic Bread, and Caesar Salad Nutrition Facts</strong><br/>
        Lasagna (large slice, approx. 12 oz)<br/>
Total Carbohydrates: 40-50g<br/>
Pasta: 25-30g<br/>
Sauce: 10-15g<br/>
Cheese: 5-10g<br/>
Fiber: 4-6g<br/>
Sugar: 10-15g<br/>
Garlic Bread (1 piece, approx. 3 oz)<br/>
Total Carbohydrates: 20-25g<br/>
Bread: 15-20g<br/>
Garlic butter: 5g<br/>
Fiber: 1-2g<br/>
Sugar: 2-5g<br/>
Caesar Salad (moderate size)<br/>
Total Carbohydrates: 10-15g<br/>
Lettuce: 2-5g<br/>
Croutons: 5-10g<br/>
Dressing: 5g<br/>
Fiber: 5-10g<br/>
Sugar: 5g<br/>
Total Carbohydrates<br/>
Approximately 70-90g<br/>
Please note that exact values may vary depending on:<br/>
Ingredients (pasta, sauce, cheese)<br/>
Garlic bread size and preparation<br/>
Salad dressing and crouton quantity<br/>
Would you like nutrition information for other meals or help tracking daily carb intake?
      </>
    ),
    fifth: (
      <>
        <strong>Biryani, Raita, and Gulab Jamun Nutrition Facts</strong><br/>
        Homemade Biryani (moderate serving, approx. 1.5 cups)<br/>
Total Carbohydrates: 60-80g<br/>
Basmati rice: 40-50g<br/>
Vegetables/meat: 10-20g<br/>
Spices: negligible<br/>
Fiber: 4-6g<br/>
Sugar: 5-10g<br/>
Raita (moderate serving, approx. 1 cup)<br/>
Total Carbohydrates: 10-15g<br/>
Yogurt: 5-10g<br/>
Cucumber/tomato: 2-5g<br/>
Spices: negligible<br/>
Fiber: 2-4g<br/>
Sugar: 5-10g<br/>
Gulab Jamun (2-3 pieces, approx. 100g)<br/>
Total Carbohydrates: 25-35g<br/>
Sugar: 20-30g<br/>
Milk solids: 5-10g<br/>
Fiber: negligible<br/>
Sugar: 20-30g<br/>
Total Carbohydrates<br/>
Approximately 95-130g<br/>
Please note that exact values may vary depending on:<br/>
Ingredients (rice, spices, yogurt)<br/>
Biryani cooking method (amount of oil/ghee)<br/>
Gulab Jamun size and sweetness<br/>
Would you like nutrition information for other meals or help tracking daily carb intake?
      </>
    ),
  };
   
  return (
    <div>

        <div>
            <div className="p-4">
                <h2 className="text-white text-lg font-bold mb-2">Messages</h2>
                <div className="flex justify-around mb-4">
                <button
            className={`p-3 rounded-3xl text-sm transition duration-300 ${activeButton === 'first' ? 'bg-black text-white' : 'bg-transparent text-black hover:bg-[rgba(51,51,51,0.3)] hover:text-black'}`}
            onClick={() => handleButtonClick('first')}
          >
            Simple Breakfast
          </button>
          <button
            className={`p-3 rounded-3xl text-sm transition duration-300 ${activeButton === 'second' ? 'bg-black text-white' : 'bg-transparent text-black hover:bg-[rgba(51,51,51,0.3)] hover:text-black'}`}
            onClick={() => handleButtonClick('second')}
          >
            Simple Lunch
          </button>
          <button
            className={`p-3 rounded-3xl text-sm transition duration-300 ${activeButton === 'third' ? 'bg-black text-white' : 'bg-transparent text-black hover:bg-[rgba(51,51,51,0.3)] hover:text-black'}`}
            onClick={() => handleButtonClick('third')}
          >
            Mixed Restaurant Meal
          </button>
          <button
            className={`p-3 rounded-3xl text-sm transition duration-300 ${activeButton === 'fourth' ? 'bg-black text-white' : 'bg-transparent text-black hover:bg-[rgba(51,51,51,0.3)] hover:text-black'}`}
            onClick={() => handleButtonClick('fourth')}
          >
            Multi-Component Home-Cooked Meal
          </button>
          <button
            className={`p-3 rounded-3xl text-sm transition duration-300 ${activeButton === 'fifth' ? 'bg-black text-white' : 'bg-transparent text-black hover:bg-[rgba(51,51,51,0.3)] hover:text-black'}`}
            onClick={() => handleButtonClick('fifth')}
          >
            Complex Cultural Dish
          </button>

                </div>
            </div>
        </div>

        <div className="flex mt-10">

                  <div className="w-1/2 bg-[#171717] mr-2" style={{height: '650px', overflowY: 'scroll', borderRadius: '7px'}}>
                  <div 
                    className="bg-[#171717]"
                    style={{
                      position: 'sticky',
                      top: '0',
                      zIndex: '10',
                      padding: '7px 0',
                  }}>
                    <div className="p-4">
                      <h2 className="text-white text-lg font-bold mb-2">diAI</h2>
                      <hr style={{ backgroundColor: 'grey', height: '1px', border: 'none' }} />
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="space-y-4 mt-5">
                      <div>
                        <p>
                          <div style={{textAlign: 'right'}}>
                            <span className="text text-white font-semibold">User</span><br/>
                          </div> 
                          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                          <div className="text text-white bg-[#232323] p-3" style={{borderRadius: '10px', width: 'fit-content'}}>
                            {texts[activeText]}
                          </div>
                          </div>
                        </p>
                        <br/>


                        <p className="text text-white flex items-center" onClick={toggleText}>
                          <span>Thought for 1 second</span>
                          <span className="ml-1">
                            <svg
                              style={{ marginTop: '1px', marginRight: '1px' }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              fill="currentColor"
                              className="bi bi-chevron-down"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                              />
                            </svg>
                          </span>
                        </p>

                        {isExpanded ? (
                            <div style={{ marginTop: '10px' }}>
                              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <div className="text text-white">
                                  {expandedResponses[activeText]}
                                  <br/><br/>
                                  {expandedResponses2[activeTextt]}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div style={{ marginTop: '10px' }}>
                            <p className="text text-white">
                              {expandedResponses2[activeTextt]}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  </div>

                  {/* Right Div */}
                  <div className="w-1/2 bg-[#171717] ml-2" style={{height: '650px', overflowY: 'scroll', borderRadius: '7px'}}>
                  <div 
                    className="bg-[#171717]"
                    style={{
                      position: 'sticky',
                      top: '0',
                      zIndex: '10',
                      padding: '7px 0',
                  }}>
                    <div className="p-4">
                      <h2 className="text-white text-lg font-bold mb-2">Llama 3.2</h2>
                      <hr style={{ backgroundColor: 'grey', height: '1px', border: 'none' }} />
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="space-y-4 mt-5">
                      <div>
                        <p>
                          <div style={{textAlign: 'right'}}>
                            <span className="text text-white font-semibold">User</span><br/>
                          </div> 
                          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                          <div className="text text-white bg-[#232323] p-3" style={{borderRadius: '10px', width: 'fit-content'}}>
                            {texts[activeText]}
                          </div>
                          </div>
                        </p>
                        <br/>

                        <div className="text text-white" style={{ marginTop: '10px' }}>
                          
                            <div>

                              
                            {expandedResponses3[activeTexttt]}
                            </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
    </div>
  )
}

export default Llmmessages








// import React from 'react'
// import { useState } from 'react';

// function Llmmessages() {

//     const [isExpanded, setIsExpanded] = useState(false);

//     const toggleText = () => {
//       setIsExpanded(!isExpanded);
//     };


//   return (
//     <div>
//         <div className="flex mt-10">
//                   <div className="w-1/2 bg-[#171717] mr-2" style={{height: '650px', overflowY: 'scroll', borderRadius: '7px'}}>
//                   <div 
//                     className="bg-[#171717]"
//                     style={{
//                       position: 'sticky',
//                       top: '0',
//                       zIndex: '10',
//                       padding: '7px 0',
//                   }}>
//                     <div className="p-4">
//                       <h2 className="text-white text-lg font-bold mb-2">diAI</h2>
//                       <hr style={{ backgroundColor: 'grey', height: '1px', border: 'none' }} />
//                     </div>
//                   </div>
//                   <div className="p-4">
//                     <div className="space-y-4 mt-5">
//                       <div>
//                         <p>
//                           <div style={{textAlign: 'right'}}>
//                             <span className="text text-white font-semibold">User</span><br/>
//                           </div> 
//                           <div style={{display: 'flex', justifyContent: 'flex-end'}}>
//                           <div className="text text-white bg-[#232323] p-3" style={{borderRadius: '10px', width: 'fit-content'}}>
//                           I had a big breakfast with few eggs <br/>and 2 peaces toast, and then grabbed a <br/>quick standard sandwich for lunch.
//                           </div>
//                           </div>
//                         </p>
//                         <br/>
//                         <p className="text text-white flex items-center" onClick={toggleText}>
//                           <span>Thought for 1 second</span>
//                           <span className="ml-1">
//                             <svg
//                               style={{ marginTop: '1px', marginRight: '1px' }}
//                               xmlns="http://www.w3.org/2000/svg"
//                               width="12"
//                               height="12"
//                               fill="currentColor"
//                               className="bi bi-chevron-down"
//                               viewBox="0 0 16 16"
//                             >
//                               <path
//                                 fillRule="evenodd"
//                                 d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
//                               />
//                             </svg>
//                           </span>
//                         </p>

//                         {isExpanded ? 
//                           <div style={{ marginTop: '10px' }}>
//                             <div style={{
//                               display: 'flex',
//                               alignItems: 'flex-start', // Aligns items at the top
//                             }}>
//                               <div className="text text-white">
//                                 <strong>Breakfast:</strong>
//                                 <p style={{ marginTop: '7px' }}>
//                                   <strong>Eggs:</strong> Eggs are low in carbs. Typically, one large egg has about 1 gram of carbs. So for a few eggs (let's say 3), that would be about 3 grams.
//                                   <br />
//                                   <strong>Toast:</strong> Standard white or whole grain toast usually has around 12-15 grams of carbs per slice. For 2 slices, that would be about 24-30 grams.
//                                   <br />
//                                   <strong>Breakfast Total:</strong> Approximately 27-33 grams of carbs.
//                                 </p>
//                                 <p>
//                                   <strong>Lunch:</strong>
//                                   <br />
//                                   Standard Sandwich: This can vary widely based on the type of bread and fillings, but a typical sandwich with two slices of bread and standard fillings might contain around 30-40 grams of carbs.
//                                   <br />
//                                   <strong>Lunch Total:</strong> Approximately 30-40 grams of carbs.
//                                 </p>
//                                 <br/>
//                                 <p>
//                                   Combining both meals, you're looking at roughly 57-73 grams of carbs for the day so far. If you provide more details about the sandwich or any additional snacks, I can refine the estimate!
//                                 </p>
//                               </div>
//                             </div>

//                           </div>
//                           :
//                           <div style={{ marginTop: '10px' }}>
//                             <p className="text text-white">
//                               Combining both meals, you're looking at roughly 57-73 grams of carbs for the day so far. If you provide more details about the sandwich or any additional snacks, I can refine the estimate!
//                             </p>
//                           </div>
//                         }
//                       </div>
//                     </div>
//                   </div>
//                   </div>

//                   {/* Right Div */}
//                   <div className="w-1/2 bg-[#171717] ml-2" style={{height: '650px', overflowY: 'scroll', borderRadius: '7px'}}>
//                   <div 
//                     className="bg-[#171717]"
//                     style={{
//                       position: 'sticky',
//                       top: '0',
//                       zIndex: '10',
//                       padding: '7px 0',
//                   }}>
//                     <div className="p-4">
//                       <h2 className="text-white text-lg font-bold mb-2">Llama 3.2</h2>
//                       <hr style={{ backgroundColor: 'grey', height: '1px', border: 'none' }} />
//                     </div>
//                   </div>
//                   <div className="p-4">
//                     <div className="space-y-4 mt-5">
//                       <div>
//                         <p>
//                           <div style={{textAlign: 'right'}}>
//                             <span className="text text-white font-semibold">User</span><br/>
//                           </div> 
//                           <div style={{display: 'flex', justifyContent: 'flex-end'}}>
//                           <div className="text text-white bg-[#232323] p-3" style={{borderRadius: '10px', width: 'fit-content'}}>
//                           I had a big breakfast with few eggs <br/>and 2 peaces toast, and then grabbed a <br/>quick standard sandwich for lunch.
//                           </div>
//                           </div>
//                         </p>
//                         <br/>

//                         <div className="text text-white" style={{ marginTop: '10px' }}>
                          
//                             <div>

                              
//                               Here's the carb breakdown:<br/>
//                               <strong>Breakfast</strong><br/>
//                               2-3 eggs: 1.8-2.1g carbs (negligible)<br/>
//                               2 slices of toast (white bread): 24-30g carbs<br/>
//                               Total breakfast carbs: 25.8-32.1g<br/>
//                               <strong>Lunch:</strong><br/>
//                               Standard sandwich:<br/>
//                               2 slices of bread (white): 20-24g carbs<br/>
//                               Fillings (turkey, ham, cheese, veggies): 5-10g carbs<br/>
//                               Total lunch carbs: 25-34g<br/>
//                               <strong>Total daily carbs</strong><br/>
//                               50.8-66.1g<br/>
//                               Let me know if you'd like more precise estimates or have other meals to report!
//                             </div>

//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   </div>
//                   </div>
//     </div>
//   )
// }

// export default Llmmessages
import React, { useEffect } from 'react';
import StaggerText from "react-stagger-text";
import image from "../images/diai_new_1_white.png";
import AOS from "aos";
import "aos/dist/aos.css";
import './Resizing.css';

function BodySm() {

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 max-w-7xl" style={{margin: 'auto'}}>


    <p className="text-gray-100">products</p>
    <div className="border-t border-gray-100 mb-5"></div>
    {/* Main content section */}
    <div style={{marginTop: '100px'}} className="flex flex-col md:flex-row items-center justify-center mt-5">
      <div className="padding-left w-full mb-10 md:mb-0 flex flex-col justify-center md:text-left">
        <h1 className="text-2xl font-semibold mb-5">
          The Intersection of Hardware and Software
        </h1>
        <p className="text-lg leading-8 text-gray-900 mb-10">
          <StaggerText>
            In today's digital health landscape, the most innovative solutions arise when cutting-edge hardware meets sophisticated software. diAI system combines a sleek, wearable device with powerful AI-driven software to revolutionize diabetes management. By bridging the physical and digital realms, we've created a seamless experience that empowers diabetics to take control of their health with unprecedented ease and accuracy.
          </StaggerText>
        </p>
        <div data-aos="fade-right" className="flex flex-col sm:flex-row gap-4">
          <a style={{textAlign: 'center'}} href="/the-pod" className="block mb-2 text-base sm:text-lg font-semibold leading-7 text-black">
            Read More <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>

      {/* Image section */}
      <div className="md:w-3/4 mb-12 w-full flex justify-center">
        <div className="rounded-2xl">
          <img src={image} className="w-80 h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg" alt="diAI wearable device" />
        </div>
      </div>
    </div>
  </div>
  );
}

export default BodySm;
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

// Calculate MAE for categories
const calculateCategoryMAE = (real, estimated) => {
  let totalError = 0;
  real.forEach((value, index) => {
    totalError += Math.abs(value - estimated[index]);
  });
  return totalError / real.length;
};

// Calculate RMSE for categories
const calculateCategoryRMSE = (real, estimated) => {
  let squaredErrorSum = 0;
  real.forEach((value, index) => {
    squaredErrorSum += Math.pow(value - estimated[index], 2);
  });
  return Math.sqrt(squaredErrorSum / real.length);
};

const NutritionalIntelligenceGraph = () => {
  // Data for food categories with real and estimated carbs
  const categoriesData = [
    {
      category: "Fruits",
      realCarbs: [50, 50, 60],
      estimatedCarbs: [55, 52, 65],
    },
    {
      category: "Vegetables",
      realCarbs: [5, 7],
      estimatedCarbs: [6, 8],
    },
    {
      category: "Grains",
      realCarbs: [28, 37.5, 38.85],
      estimatedCarbs: [30, 38, 39],
    },
    {
      category: "Snacks/Sweets",
      realCarbs: [20, 28, 80, 40],
      estimatedCarbs: [22, 30, 84, 41],
    },
  ];

  // Prepare data for the chart
  const categories = categoriesData.map((item) => item.category);
  const realCarbs = categoriesData.map((item) => item.realCarbs.reduce((acc, val) => acc + val, 0));
  const estimatedCarbs = categoriesData.map((item) => item.estimatedCarbs.reduce((acc, val) => acc + val, 0));

  // Calculate MAE and RMSE for each category
  const mae = categoriesData.map((item) => calculateCategoryMAE(item.realCarbs, item.estimatedCarbs));
  const rmse = categoriesData.map((item) => calculateCategoryRMSE(item.realCarbs, item.estimatedCarbs));

  // Chart data for carbohydrate estimation accuracy comparison
  const chartData = {
    labels: categories,
    datasets: [
      {
        label: "diAI",
        data: estimatedCarbs,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1
      },
      {
        label: "Real Carbs (g)",
        data: realCarbs,
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1
      }
    ]
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: ""
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}g`
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Food Category"
        }
      },
      y: {
        title: {
          display: true,
          text: "Total Carbs (g)"
        },
        beginAtZero: true
      }
    }
  };

  return (
    <div style={{ margin: "auto" }}>
      <br/>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default NutritionalIntelligenceGraph;

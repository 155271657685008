import React from 'react';

const DataDashboard = () => {
  return (
    <div className="bg-black text-white min-h-screen p-6">
      <div className="text-xs mb-4 text-gray-500">
        Results | Methods | Generalizing to broader preferences | Limitations | Next steps
      </div>

      <div className="text-sm mb-4">
        This is dummy data This is dummy data This is dummy data This is dummy data...
      </div>

      <div className="grid grid-cols-2 gap-8">
        {/* Left Column */}
        <div>
          <div className="text-lg font-semibold mb-2">Dataset</div>
          <div className="text-2xl font-bold mb-4">Dummy Data</div>
          <div className="space-y-4">
            <Metric label="GPT" value="0.233" />
            <Metric label="Supervised Fine-Tuning" value="0.199" />
            <Metric label="InstructGPT" value="0.196" highlight />
          </div>
        </div>

        {/* Right Column */}
        <div>
          <div className="text-lg font-semibold mb-2">Dataset</div>
          <div className="text-2xl font-bold mb-4">Dummy Data</div>
          <div className="space-y-4">
            <Metric label="GPT" value="0.224" />
            <Metric label="Supervised Fine-Tuning" value="0.206" />
            <Metric label="Dummy Data" value="0.413" highlight />
          </div>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-2 gap-8">
        {/* Left Column - Hallucinations */}
        <div>
          <div className="text-lg font-semibold mb-2">Dummy Data</div>
          <div className="text-2xl font-bold mb-4">Hallucinations</div>
          <div className="space-y-4">
            <Metric label="GPT" value="0.414" />
            <Metric label="Supervised Fine-Tuning" value="0.078" highlight />
            <Metric label="InstructGPT" value="0.172" />
          </div>
        </div>

        {/* Right Column - API Dataset */}
        <div>
          <div className="text-lg font-semibold mb-2">API Dataset</div>
          <div className="text-2xl font-bold mb-4">Dummy Data</div>
          <div className="space-y-4">
            <Metric label="GPT" value="0.811" />
            <Metric label="Supervised Fine-Tuning" value="0.880" />
            <Metric label="InstructGPT" value="0.902" highlight />
          </div>
        </div>
      </div>

      <div className="mt-12 text-sm text-gray-400">
        Evaluating InstructGPT for toxicity, truthfulness, and appropriateness... Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...
      </div>
      <div className="mt-12 text-sm text-gray-400">
        Evaluating InstructGPT for toxicity, truthfulness, and appropriateness... Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...Evaluating InstructGPT for toxicity, truthfulness, and appropriateness...
      </div>
    </div>
  );
};

// Metric Component
const Metric = ({ label, value, highlight }) => (
  <div className="flex items-center">
    <span className="w-24">{label}</span>
    <div className="relative w-64 h-4 bg-gray-700 rounded-full overflow-hidden">
      <div
        className={`h-full ${highlight ? 'bg-blue-600' : 'bg-gray-400'}`}
        style={{ width: `${value * 100}%` }}
      />
    </div>
    <span className="ml-4 text-white">{value}</span>
  </div>
);

export default DataDashboard;
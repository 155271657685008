import React, { useState, useEffect } from 'react';
import StaggerText from "react-stagger-text"
import image1 from "../images/the_pod-image-1.png"
import image2 from "../images/the_pod-image-2.png"
import image3 from "../images/the_pod-image-3.png"
import image6 from "../images/exportph.webp"
import image7 from "../images/daybehindph.webp"
import image4 from "../images/sphsp.webp"
import AOS from "aos";
import "aos/dist/aos.css";


function BodyOne() {



  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);


  return (

    <div className=" mx-auto py-16 px-16">



        <h1 className="mt-12 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Everywhere you want to go</h1>
                <p className="mt-6 text-lg sm:text-xl leading-8 text-gray-700">
                Whether you’re dining out, cooking at home, or snacking on the go, the diAI Pod and App have you covered. Simply press the action button to activate the necklace and freely describe what you are about to consume—no need for specific structures or keywords. The Pod takes it from there, using AI to analyze your words.
                </p>

                <div class="flex mt-8 gap-4 flex-row">
                    <div class="basis-1/3">
                        <img className="w-full rounded-2xl" src={image1}></img>
                    </div>
                    <div class="basis-1/3">
                        <img className="w-full rounded-2xl" src={image2}></img>
                    </div>
                    <div class="basis-1/3">
                        <img className="w-full rounded-2xl" src={image3}></img>
                    </div>

                </div>

        

                <h1 className="mt-12 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Everything you want to do</h1>
                <p className="mt-6 text-lg sm:text-xl leading-8 text-gray-700">
                Log meals, set reminders, track your progress, and even share reports with your healthcare provider – all with the power of your voice and a few taps on your smartphone. Our AI-powered system learns from your habits and provides
        personalized recommendations, making diabetes management not just easier, but smarter.
                </p>

                <div class="flex mt-8 gap-4 flex-row">
                    <div class="basis-1/3">
                        <img className="w-full rounded" src={image4}></img>
                    </div>
                    <div class="basis-1/3">
                        <img className="w-full rounded" src={image6}></img>
                    </div>
                    <div class="basis-1/3">
                        <img className="w-full rounded" src={image7}></img>
                    </div>

                </div>
    </div>

   
    
   
  );
}

export default BodyOne;
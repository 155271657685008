import React, { useEffect, useState } from 'react';
import db from "../firebase"
import firebase from "firebase/compat/app";
import Marquee from "react-fast-marquee"
import { CheckIcon } from '@heroicons/react/20/solid'
import StaggerText from "react-stagger-text"

const includedFeatures = [
  'diAI Computer Pod',
  'Access to diAI App',
  'Threaded Necklace',
  'USB-C Charging',
]


function Waitlist() {

  const [showTextbox, setShowTextbox] = useState(false);
  const [finished, setFinished] = useState(false);
  const [typeEmail, setTypeEmail] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);

  const currentEmail = (event) =>{
    setTypeEmail(event.target.value);
  }

  const submitEmail = async (e) => {
    e.preventDefault();

    console.log("function runs")

    if (typeEmail=="") {
      console.log("Fill")
    } else {
      db.collection("emails").add({
        email: typeEmail,
      }).then(() => {
        setTypeEmail('');
        setFinished(true);
        console.log("IT WORKS")
      }).catch((error) => {
        console.error("Error adding document: ", error);
        console.log("IT WORKS")
      });

    }

    
  }

  const repeatedText = Array(60).fill("et and stylish necklace that is more than just a fashion statement. A discre").join(" ");
  const repeatedTextTwo = Array(60).fill(' Join the waitlist to expirence the future.').join(' ');




  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
        <div className="bg-gray-100 text-gray-500">
          <a className="p-2" href="/"><span aria-hidden="true">&larr;</span> Home </a>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
        </div>
        <br/><br/>
        <div className="container mx-auto pt-16 pb-8 px-16">

              <div class=" justify-center ...">
                <p className="text-xl leading-8 text-gray-900 mb-10">
                  <StaggerText>
                    We're excited to bring the diAI system to diabetics worldwide. The diAI Pod and App will be available for purchase through our official website and select healthcare providers. Join our waitlist to be among the first to experience this groundbreaking technology. We're also partnering with diabetes clinics and nutritionists to ensure that the diAI system integrates seamlessly into existing care plans. Stay tuned for announcements on launch dates and special offers for early adopters.

                  </StaggerText>
                </p>

                <p className="text-xl leading-8 text-gray-900 mb-10">
                  <StaggerText
                    startDelay={2000}
                  >

                  If you are interested in owning a Pod, we have good news! We are very close to finishing our work with the manufacturers and will start mass-producing the Pod in Q4 2024. Please stay tuned for the full release. Until then, we will be allowing preorders for the first batch of 1,000 units. If you are able to preorder, it means we have not yet reached our limit.

                  </StaggerText>
                </p>


                <div className="mt-10 flex items-center gap-x-4">
                        <h4 className="flex-none text-sm font-semibold leading-6 text-gray-900">What’s included</h4>
                        <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul
                  role="list"
                  className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                >
                  {includedFeatures.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-gray-900" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <div>
                  {!finished ? (
                    <div>
                    <form class="w-full pt-6">
                      <div class="flex items-center border-b border-white-500 py-2">
                        <input type="email" placeholder="Enter your email" class="appearance-none bg-transparent border-none w-full text-gray-900 mr-3 py-1 px-2 leading-tight focus:outline-none" aria-label="Full name" maxLength={35} value={typeEmail} onChange={currentEmail} />

                        {/* <button class="flex-shrink-0 border-transparent border-4 text-white text-sm py-1 px-2 rounded" type="button" onClick={submitEmail}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                          </svg>
                        </button> */}
                      </div>
                    </form>

                    <button
                      type="button"
                        onClick={submitEmail}
                        className="mt-6 block w-full rounded-md bg-[#d9d9d9] px-3 py-2 text-center text-sm font-semibold text-black shadow-sm hover:bg-[#bfbfbf] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Join Waitlist
                      </button>
                      <p className="mt-6 text-xs leading-5 text-gray-600">
                        Joining the waitlist puts you in <b>no</b> obligation to purchase diAI Pod.
                      </p>


                    </div>

                    
                  ) : (
                    <button className="mt-6 Stay-Tuned-button"> 
                      Stay Tuned
                    </button>
                  )}
                </div>

               

              </div>
        </div>

       
    </div>
   
  );
}

export default Waitlist;

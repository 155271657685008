import Marquee from "react-fast-marquee"
import { CheckIcon } from '@heroicons/react/20/solid'
import StaggerText from "react-stagger-text"


function Privacy() {



  return (
    <div>
        <div className="bg-gray-100 text-gray-500">
          <a className="p-2" href="/"><span aria-hidden="true">&larr;</span> Home </a>
            <hr/>
            <div className="px-16" style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <p>Privacy</p>
            </div>
            <hr/>
          
            <hr/>
        </div>
        <br/><br/>
        <div className="container mx-auto pt-16 pb-8 px-16 sm:px-32">

              <div class=" justify-center ...">
              <div>
                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>1. Introduction</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    Welcome to diAI's privacy policy. At diAI, we are committed to protecting your privacy and ensuring the security of your personal information. This privacy policy explains how our app and wearable device, diAI Pod, collect, use, and safeguard your data.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>2. Information We Collect</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information about an identifiable individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>I - Audio Data</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    diAI records audio data through the diAI Pod wearable device. The recorded audio is processed locally on your device using Deepgram for transcription.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>II - Transcription Data</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    Transcriptions of your audio data are generated locally on your device. These transcriptions are used to extract useful information through ChatGPT (ChatGPT-4o Mini), executed locally.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>III - Customer support information</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    The information extracted from the transcriptions is transformed into vector representations using Pinecone. Only the vector representations and their associated memory IDs are stored locally on your device. No text or transcription data is stored.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>3. How We Use Your Information</b>
                </p>
                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>I - Local Processing</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    All data processing, including audio recording, transcription, information extraction, and vector representation, is performed locally on your device. None of the data is transmitted to our servers or any third-party servers, except for the services mentioned (Deepgram, Chat GPT, Pinecone), which also operate locally.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>4. Data Security</b>
                </p>
                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>I - Local Storage</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    We implement robust security measures to protect your data from unauthorized access. Your data, including audio recordings, transcriptions, and vector representations, is stored locally on your device.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>II - No Backend Storage</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    All storage is confined only to your device to ensure maximum privacy. diAI does not store any of your personal data on our servers.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>5. Third-Party Services</b>
                </p>
                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>I - Deepgram</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    Deepgram is used on your device for audio transcription. Deepgram does not receive or store any of your data externally.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>II - Chat GPT</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    Chat GPT processes transcriptions on your device to extract useful information.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>III - Pinecone</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    Pinecone creates vector representations of the extracted information locally. Only the vector data and memory IDs are stored locally, without any text or transcription data.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>6. Your Data Rights</b>
                </p>
                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>I - Access and Control</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    You have full control over your data as it is stored and processed locally on your device. You can access, modify, or delete your data at any time through the diAI Pod app settings.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>7. Changes To This Privacy Policy</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    We may update this privacy policy from time to time. When we make changes, we will notify you by updating the effective date at the top of this policy. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
                    </StaggerText>
                </p>

                <p className="text-2xl leading-8 text-gray-900 mb-10">
                    <b>8. Contact Us</b>
                </p>
                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    If you have any questions or concerns about this privacy policy or our data practices, please contact us at team@diai.shop.
                    </StaggerText>
                </p>

                <p className="text-xl leading-8 text-gray-900 mb-10">
                    <StaggerText>
                    By using the diAI Pod app and diAI Pod wearable device, you acknowledge that you have read, understood, and agree to be bound by this privacy policy.
                    </StaggerText>
                </p>
                </div>

             

              </div>
        </div>

       
    </div>
   
  );
}

export default Privacy;

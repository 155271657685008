import React, { useEffect, useState } from 'react';
import db from "../firebase"
import firebase from "firebase/compat/app";

import Marquee from "react-fast-marquee"
import { CheckIcon } from '@heroicons/react/20/solid'
import StaggerText from "react-stagger-text"
import BodyOne from '../components/bodyone';




function Thepod() {

  const [showTextbox, setShowTextbox] = useState(false);
  const [finished, setFinished] = useState(false);
  const [typeEmail, setTypeEmail] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);

  const currentEmail = (event) =>{
    setTypeEmail(event.target.value);
  }

  const submitEmail = async (e) => {
    e.preventDefault();

    db.collection("emails").add({
      email: typeEmail,
    }).then(() => {
      setTypeEmail('');
      setFinished(true);
      console.log("IT WORKS")
    }).catch((error) => {
      console.error("Error adding document: ", error);
      console.log("IT WORKS")
    });
  }

  const repeatedText = Array(60).fill("et and stylish necklace that is more than just a fashion statement. A discre");
  const repeatedTextTwo = Array(60).fill(" The Hardware, The Software, and everything in-between.").join(' ');



  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
        <div className="bg-gray-100 text-gray-500">
          <a className="p-2" href="/"><span aria-hidden="true">&larr;</span> Home </a>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
          
        </div>
        
        <br/><br/>
        <div className="container mx-auto pt-16 pb-8 px-16">

              <div class=" justify-center ...">
                <p className="text-xl leading-8 text-gray-900 mb-10">
                <p className="text-gray-500">
                <StaggerText>
                 The Intersection of Hardware and Software
                  </StaggerText>
                </p>
                  <br/><br/>
                  <StaggerText>

                  In today's digital health landscape, the most innovative solutions arise when cutting-edge hardware meets sophisticated software. diAI system combines a sleek, wearable device with powerful AI-driven software to revolutionize diabetes management. By bridging the physical and digital realms, we've created a seamless experience that empowers diabetics to take control of their health with unprecedented ease and accuracy.

                  </StaggerText>
                  <br/><br/>
                 
                </p>



              </div>
        </div>

        <BodyOne/>

       
    </div>
   
  );
}

export default Thepod;


import './App.css';
import ResearchSm from "./components/researchsm"
import Header from './components/header';
import ResearchHeader from './components/researchheader';
import db from "./firebase";

import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import BodyOne from './components/bodyone';
// import Footer from './components/aboutus';
import BodySm from './components/bodysm';
import AboutUs from './components/ending';
import Footer from "./components/ending"


function Home() {


  return (
    <>
    <ResearchHeader/>
    {/* <MovingText/> */}
    <ResearchSm/>
    
    <div className='my-12'>

    </div>
    <BodySm/>
    {/* <Footer/> */}
    <div className='pt-12 m-8'>
      <Footer/>
    </div>
    </>
   
  );
}

export default Home;

import React, { useEffect, useState } from 'react';
import db from "../firebase"
import firebase from "firebase/compat/app";

import Marquee from "react-fast-marquee"
import { CheckIcon } from '@heroicons/react/20/solid'
import StaggerText from "react-stagger-text"

const includedFeatures = [
  'diAI Computer Pod',
  'Access to diAI App',
  'Threaded Necklace',
  'USBc Charging',
]



function Investor() {
  const [isdone, setIsdone] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);

  const nameType = (event) => {
    setName(event.target.value);
  }
  const emailType = (event) => {
    setEmail(event.target.value);
  }
  const messageType = (event) => {
    setMessage(event.target.value);
  }

  const JoinWaitlist = (e) => {

    if (name=="" || email=="") {

      console.log("Fill")
      
    } else {

      db.collection("diaiglassesmessage").add({
        name: name,
        email: email,
        message: message,
      });
      setIsdone(true)
      setName('');
      setEmail('');
      setMessage('');

    }

    
    
  }

  const repeatedText = Array(60).fill("et and stylish necklace that is more than just a fashion statement. A discre");
  const repeatedTextTwo = Array(60).fill(' Become the investor for the most driven startup in the arena. ').join(' ');




  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
        <div className="bg-gray-100 text-gray-500">
          <a className="p-2" href="/"><span aria-hidden="true">&larr;</span> Home </a>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='right' pauseOnHover={true}>
                    {repeatedText}
                </Marquee>
            </div>
            <hr/>
            <div style={{fontSize: '56px', paddingTop: '20px', paddingBottom: '20px'}}>
                <Marquee direction='left' pauseOnHover={true}>
                    {repeatedTextTwo}
                </Marquee>
            </div>
            <hr/>
        </div>
        <br/><br/>
        <div className="container mx-auto pt-16 pb-8 px-16">

              <div class=" justify-center ...">
                <p className="text-xl leading-8 text-gray-900 mb-10">
                  <StaggerText>
                      At diAI, we're a fresh and upcoming startup dedicated to revolutionizing diabetes management with our innovative AI wearable necklace. We are passionate about making a significant impact on the lives of those with diabetes and believe that our technology can be a game-changer. We greatly appreciate and carefully consider any help from investors. With your investment, our team will work closely with you to ensure that our collaboration meets your expectations, particularly regarding equity and overall satisfaction. Together, we can transform diabetes care and bring our vision to life.
                  </StaggerText>
                </p>


                {!isdone ? (

                  <div>
                    <form class="w-full max-w-sm py-2">
                        <div class="flex items-center border-b border-white-500 py-2">
                          <input value={name} onChange={nameType} type="email" placeholder="Enter your name" class="appearance-none bg-transparent border-none w-full text-gray-900 mr-3 py-1 px-2 leading-tight focus:outline-none" aria-label="Full name" />
                        </div>
                      </form>
                      <form class="w-full max-w-sm py-6">
                        <div class="flex items-center border-b border-white-500 py-2">
                          <input value={email} onChange={emailType} type="email" placeholder="Enter your email" class="appearance-none bg-transparent border-none w-full text-gray-900 mr-3 py-1 px-2 leading-tight focus:outline-none" aria-label="Full name"/>
                        </div>
                      </form>
                      <form class="w-full max-w-sm py-6">
                        <div class="flex items-center border-b border-white-500 py-2">
                          <textarea value={message} onChange={messageType} type="email" placeholder="Optional Note" class="appearance-none bg-transparent border-none w-full text-gray-900 mr-3 py-1 px-2 leading-tight focus:outline-none" aria-label="Full name" />
                        </div>
                      </form>
                      <p type="button" onClick={JoinWaitlist} className="text-l cursor-pointer px-2 leading-8 text-gray-900 mb-10">
                  
                          Send <span aria-hidden="true">&rarr;</span>
        
                      </p>
                  </div>

                ) : (


                  <p className="text-xl leading-8 text-gray-900 mb-10">
                  <StaggerText>
                    Thank you-We will get back to you soon.
                  </StaggerText>
                </p>


                )}


                

             
                


           

              </div>
        </div>

       
    </div>
   
  );
}

export default Investor;
